<template>
  <no-ssr>
    <div id="my-account" class="md:py-13 pt-11 bg-grey-27 pb-5">
      <div class="container px-0 md:px-sm xl:px-0">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/5 xl:w-1/4 order-1 md:order-none" v-if="!!currentUser">
            <h3 class="text-primary font-normal text-mid leading-none italic font-serif tracking-tiny hidden md:block">
              {{ $t('Your Account') }}
            </h3>
            <nav class="my-account__nav relative md:pb-36 md:mb-15 md:text-left text-center">
              <ul class="list-none p-0 mt-5">
                <li class="leading-none" v-for="(page, index) in navigation" :key="index">
                  <router-link :to="localizedRoute(page.link)" class="hover:text-primary text-h5 uppercase leading-h2 font-sans md:tracking-lg relative my-account__link block md:inline border-t border-grey-15 md:border-0 font-bold md:font-normal py-1 md:py-0"
                               :exact-active-class="activeClass">
                    {{ page.title }}
                  </router-link>
                  <ul class="my-account__sub-list md:ml-md" :class="{ 'my-account__sub-list--active': isActiveItem(page.subNavigation) }">
                    <li v-for="(subPage, index) in page.subNavigation" :key="index">
                      <router-link :to="localizedRoute(subPage.link)" class="navigation__link text-grey-32 hover:text-dark md:text-sm text-h5 leading-h2 md:leading-sm md:tracking-lg uppercase relative block md:inline border-t border-grey-15 md:border-0 font-bold md:font-normal py-1 md:py-0"
                                   :exact-active-class="navActiveClass">
                        {{ subPage.title }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <help class="hidden lg:block pr-16" />
          </div>
          <div class="w-full md:w-4/5 xl:w-3/4 md:pl-xl px-sm" v-if="!!currentUser">
            <component :is="this.$props.activeBlock" />
          </div>
        </div>
      </div>
    </div>
  </no-ssr>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import MyAccount from '@vue-storefront/core/pages/MyAccount'
import Breadcrumbs from '../components/core/Breadcrumbs'
import AffiliateDetails from '../components/core/blocks/MyAccount/AffiliateDetails'
import MyDashboard from '../components/core/blocks/MyAccount/MyDashboard'
import Help from '../components/core/blocks/MyAccount/Help'
import MyProfile from '../components/core/blocks/MyAccount/MyProfile'
import MyAddressBook from '../components/core/blocks/MyAccount/MyAddressBook'
import MyAddressEdit from '../components/core/blocks/MyAccount/MyAddressEdit'
import MyNewsletter from '../components/core/blocks/MyAccount/MyNewsletter'
import MyOrders from '../components/core/blocks/MyAccount/MyOrders'
import MyResellerCert from '../components/core/blocks/MyAccount/MyResellerCert'
import MyOrder from '../components/core/blocks/MyAccount/MyOrder'
import MyWishlist from '../components/core/blocks/MyAccount/MyWishlist'
import MyRewards from '../components/core/blocks/MyAccount/MyRewards'
import MyRewardsDetails from '../components/core/blocks/MyAccount/MyRewardsDetails'
import StoredCreditCards from 'theme/components/theme/blocks/Account/StoredCreditCards'
import WebprofitShare from 'theme/components/theme/blocks/Account/WebprofitShare'
import WebprofitDetail from 'theme/components/theme/blocks/Account/WebprofitDetail'
import UpcomingEvents from 'theme/components/theme/blocks/Account/UpcomingEvents'
import PastEvents from 'theme/components/theme/blocks/Account/PastEvents'
import InSalonClasses from 'theme/components/theme/blocks/Account/InSalonClasses'
import ClassDetails from 'theme/components/theme/blocks/Account/ClassDetails'
import ClassTickets from 'theme/components/theme/blocks/Account/ClassTickets'
import {mapState} from 'vuex'
import AffiliateStylist from 'theme/components/core/blocks/MyAccount/AffiliateStylist'
import AffiliateStylistForm from 'theme/components/core/blocks/MyAccount/AffiliateStylistForm'

export default {
  data () {
    return {
      isNavOpen: true,
      activeClass: 'my-account__link--active',
      navActiveClass: 'navigation__link--active',
      navigation: [
        {
          title: this.$t('Account info'),
          link: {
            name: 'my-account'
          }
        },
        {
          title: this.$t('Stored credit cards'),
          link: {
            name: 'stored-credit-cards'
          }
        },
        // {
        //   title: this.$t('Reseller Certificates'),
        //   link: {
        //     name: 'my-reseller-certificates'
        //   }
        // },
        {
          title: this.$t('Orders'),
          link: {
            name: 'my-orders'
          }
        },
        {
          title: this.$t('Rewards'),
          link: {
            name: 'my-rewards'
          },
          subNavigation: [
            {
              title: this.$t('Reward Details'),
              link: {
                name: 'my-rewards-details'
              }
            }
          ]
        }, {
          title: this.$t('Address book'),
          link: {
            name: 'my-address'
          }
        },
        {
          title: this.$t('Affiliate Details'),
          link: {
            name: 'affiliate-details'
          },
          subNavigation: [
            {
              title: this.$t('Stylist Manager'),
              link: {
                name: 'affiliate-stylists'
              }
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    currentPath () {
      return this.$route.path
    }
  },
  methods: {
    isActiveItem (subNavigation) {
      if (typeof subNavigation !== 'undefined') {
        for (let i = 0; i < subNavigation.length; i++) {
          let route = this.$router.resolve({
            name: subNavigation[i].link.name
          })

          if (this.currentPath.includes(route.href)) {
            return true
            break
          } else {
            continue
          }
        }
        return false
      }
    }
  },
  components: {
    'no-ssr': NoSSR,
    AffiliateDetails,
    Breadcrumbs,
    MyDashboard,
    MyProfile,
    MyAddressBook,
    MyAddressEdit,
    MyOrders,
    MyOrder,
    MyResellerCert,
    MyWishlist,
    MyRewards,
    MyRewardsDetails,
    StoredCreditCards,
    WebprofitShare,
    WebprofitDetail,
    UpcomingEvents,
    MyNewsletter,
    PastEvents,
    ClassDetails,
    InSalonClasses,
    ClassTickets,
    Help,
    AffiliateStylist,
    AffiliateStylistForm
  },
  mixins: [MyAccount]
}

</script>
<style lang="scss">
.navigation {
  &__link {
    @apply relative;

    &:after {
      @screen md {
        @apply bg-transparent absolute;
        transition: background-color 0.3s ease;
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: -1px;
      }
    }

    &:hover,
    &--active {
      @apply text-grey-28;
      @screen md {
        @apply text-primary;
      }
      &:after {
        @screen md {
          @apply bg-grey-37;
        }
      }
    }
  }
}

.my-account {
  &__nav {
    &:after {
      @screen lg {
        @apply border-t border-black absolute;
        content: '';
        width: 233px;
        height: 0;
        bottom: 80px;
        left: -33px;
        z-index: 0;
        transform-origin: center center;
        transform: rotate(-45deg);
      }
    }
  }

  &__sub-list {
    display: none;

    &--active {
      display: block;
    }
  }

  &__link {
    &:after {
      @screen md {
        @apply bg-transparent absolute w-full left-0 bottom-0 h-px right-0;
        transition: background-color 0.3s ease;
        content: "";
      }
    }

    &--active,
    &:hover {
      &:after {
        @apply bg-grey-37;
      }
    }

    &--active {
      &~ul {
        display: block;
      }
    }
  }
}

</style>

<template>
  <div class="referral bg-white px-5 lg:px-8.5 lg:pt-13 pb-8 lg:pb-13 mb-5">
    <h4
      class="font-normal lg:font-bold text-h5 uppercase text-black lg:tracking-mid leading-none lg:leading-h4 mb-sm lg:mx-0 -mx-5 p-5 lg:p-0 tracking-sm"
      :class="getTitleClass"
    >
      <span>
        {{ card.name }}
      </span>
      <span>
        {{ $t('Stored Credit Cards on File') }}
      </span>
    </h4>
    <table class="table mt-9.5 lg:mt-13">
      <thead>
        <tr class="border-0">
          <th>
            {{ $t('Type') }}
          </th>
          <th>
            {{ $t('Card Number') }}
          </th>
          <th>
            {{ $t('Exp Date') }}
          </th>
          <th>
            {{ $t('Location') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(storedCard, index) in card.storedCards"
          :key="index"
        >
          <td :class="{'expired': storedCard.expired}">
            <span class="block uppercase text-h5 leading-sm tracking-lg">
              {{ storedCard.type }}<br>
              <span v-if="storedCard.expired">Expired*</span>
            </span>
            <span
              class="font-bold text-average leading-h5 tracking-xs"
              v-if="storedCard.isDefault"
            >
              {{ $t('Default Credit Card') }}
            </span>
          </td>
          <td :class="{'expired': storedCard.expired}">
            {{ storedCard.number }}

          </td>
          <td :class="{'expired': storedCard.expired}">
            {{ storedCard.expDate }}
          </td>
          <td :class="{'expired': storedCard.expired}">
            {{ getLocationLabel(storedCard) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-11 font-bold text-average leading-h5 tracking-xs">
      <span>
        {{ $t('In order to change your stored cards please contact your LBP Account consultant:') }}
      </span>
      <a href="tel:305-600-1305" class="text-brown-2 hover:underline">
        <span>305 600 1305</span>
      </a>
      <div class="mt-1 text-base font-normal"><small>* Denotes a card is expired.</small></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StoredCards',
  props: {
    card: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    getShippingLocations () {
      return this.currentUser && this.currentUser.extension_attributes.locations_data ? this.currentUser.extension_attributes.locations_data : []
    },
    getTitleClass () {
      return this.card.brandCode ? `title--${this.card.brandCode}` : ''
    }
  },
  methods: {
    getLocationLabel (card) {
      return card && card.location ? card.location.nick_name + ` / ${card.location.location_id}` : 'No Matching Location'
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    &--education,
    &--elz {
      @apply bg-black text-white;
      @screen lg {
        @apply bg-transparent text-black;
      }
    }

    &--rco {
      @apply bg-green;
      @screen lg {
        @apply bg-transparent;
      }
    }
    &--sc {
      @apply bg-red;
      @screen lg {
        @apply bg-transparent;
      }
    }
    &--v76 {
      @apply bg-yellow;
      @screen lg {
        @apply bg-transparent;
      }
    }
  }

  .table {
    .expired {
      @apply text-grey-25;
    }

    td {
      @apply text-h5 leading-sm tracking-lg py-xl;
    }

    tr {
      &:last-child {
        td {
          @apply border-b;
        }
      }
    }
  }
</style>

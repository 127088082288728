<template>
  <div>
    <i class="ss-gizmo ss-help text-2md leading-none"></i>
    <p class="leading-sm text-above-md">{{ $t('Have questions about your account?') }}</p>
    <p class="leading-sm text-above-md">
      <router-link :to="localizedRoute({ name: 'faq' })" class="slow-color-change whitespace-no-wrap outline-none focus:outline-none text-primary2 hover:underline">
        {{ $t('View our FAQs') }}
      </router-link> {{ $t('or contact us at') }} <a class="slow-color-change whitespace-no-wrap outline-none focus:outline-none text-primary2 hover:underline" :href="`tel:${phone}`">{{ phone }}</a>
      {{ $t('or') }} <a class="slow-color-change whitespace-no-wrap outline-none focus:outline-none text-primary2 hover:underline" :href="`mailto:${email}`">{{ email }}</a>
    </p>
  </div>
</template>
<script>
export default {
  name: 'Help',
  data () {
    return {
      phone: '305-600-1305',
      email: 'support@luxbp.com'
    }
  }
}
</script>
<style lang="scss" scoped>


</style>

<template>
  <div class="px-8.5 pt-5 pb-10 bg-white mb-5">
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{details.title}}
    </h2>
    <div class="flex justify-between">
      <div class="w-1/3">
        <img :src="details.image" :alt="details.name">
        <div class="mt-lg">
          <span class="text-black font-normal black tracking-tiny text-mid leading-h4 font-serif italic">{{ $t('When') }}</span>
          <span class="block font-sans text-base leading-sm">{{details.date}}</span>
          <span class="block font-sans text-base leading-sm">{{details.time}}</span>
        </div>
        <div class="mt-10">
          <span class="text-black font-normal black tracking-tiny text-mid leading-h4 font-serif italic">{{ $t('Where') }}</span>
          <span class="block font-sans text-base leading-sm">{{details.name}}</span>
          <span class="block font-sans text-base leading-sm">{{details.address}}</span>
          <span class="block font-sans text-base leading-sm">{{details.city}}</span>
        </div>
      </div>
      <div class="w-7/12">
        <p class="pb-7 font-sans text-above-md leading-sm">
          {{details.description}}
        </p>
        <span class="uppercase text-h5 tracking-mid block mb-5">Downloadable materials</span>
        <a
          href="#"
          target="_blank"
          class="w-full block mb-10 requirements-link bg-white border-solid border border-grey-6 text-base font-bold relative"
        >
          <span>{{details.download_title}}</span>
          <i class="ss-gizmo ss-download requirements-link__icon absolute text-2h6 text-brown-2"></i>
        </a>
        <div class="">
          <router-link
            :to="details.link_tickets"
            class="uppercase text-h5 leading-none pl-px class-link hover:text-brown-2 relative"
          >
            {{ $t('View ticket') }}
          </router-link>
          <span class="uppercase text-h5 leading-none block pt-5 pl-1 tracking-mid">{{ $t('Links') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountClassDetails',
  props: {
    details: {
      type: Object,
      required: false,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
  $grey-38: #c2c2c2;
  .class-link {
    transition: color .5s ease;
    &:after {
      @apply absolute bg-grey-37 w-full;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      transition: color .5s ease;
    }
    &:hover {
      &:after {
        @apply bg-brown-2;
      }
    }
  }
  .requirements-link {
    padding: 17px 60px 17px 23px;
    transition: background-color .5s ease;
    &__icon {
      right: 23px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: rgba($grey-38, .3);
    }
  }
</style>

<template>
  <div class="flex items-start">
    <div>
      <h3 v-if="isBilling || isShipping" class="text-primary lg:font-normal lg:text-above-md text-h5 uppercase leading-none lg:italic lg:font-serif font-sans tracking-tiny mb-sm lg:capitalize leading-loose">
        {{ isBilling ? $t('Default Billing Address') : $t('Default Shipping Address') }}
      </h3>
      <template v-if="address">
        <div class="lg:leading-h5 lg:text-above-md text-h4 text-dark tracking-xs leading-base">
          <p>{{ address.firstName }} {{ address.lastName }}</p>
          <p v-if="address.company">
            {{ address.company }}
          </p>
          <p>
            {{ address.street }}
            <span v-if="address.house"> {{ address.house }}</span>
          </p>
          <p>
            {{ address.city }}, <span v-if="address.region">{{ address.region }}, </span> {{ address.postcode }}
          </p>
          <p>
            {{ countryName }}
          </p>
          <p>
            {{ $t('T') }}: {{ address.phone }}
          </p>
          <p v-if="address.fax">
            {{ $t('F') }}: {{ address.fax }}
          </p>
        </div>
      </template>
      <p v-else class="leading-h5 text-above-md text-dark tracking-xs">
        {{
          isBilling ? $t('You have no default billing address in your address book.') :
          isShipping ? $t('You have no default shipping address in your address book.') :
          $t('You have no additional address entries in your address book.')
        }}
      </p>
    </div>
  </div>
</template>

<script>
import toString from 'lodash-es/toString'

const Countries = require('@vue-storefront/i18n/resource/countries.json')

export default {
  name: 'MyAddress',
  props: {
    address: {
      type: Object,
      required: false,
      default: null
    },
    isBilling: {
      type: Boolean,
      required: false,
      default: false
    },
    isShipping: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    countryName () {
      if (this.address && this.address.country) {
        for (let i = 0; i < Countries.length; i++) {
          if (Countries[i].code === this.address.country) {
            return Countries[i].name
          }
        }
      }
      return ''
    }
  }
}
</script>

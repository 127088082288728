import {mapState} from 'vuex'

export default {
  name: 'BrandPermissions',
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      brands: (state) => state.brands.brands
    })
  },
  data () {
    return {
      brandLocations: this.getCurrentTab ? this.getCurrentTab : ''
    }
  },
  beforeMount () {
    this.brandLocations = this.currentUser ? ((this.currentUser || {}).extension_attributes || {}).locations_data : []
  },
  methods: {
    hasBrandPermission (brandId) {
      return !!this.brandLocations.find(location => location.brand_id === brandId && location.status === true)
    },
    hasBrandPermissionByCode (code) {
      let brand = this.brands.find(x => x.code === code.toLowerCase()) || {}

      if (!brand.is_active) return false

      return !!this.brandLocations.find(location => location.brand_id === brand.id && location.status === true)
    }
  }
}

<template>
  <div class="stored-credit-cards">
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Stored Credit Cards') }}
    </h2>
    <stored-card-tabs />
  </div>
</template>

<script>
import StoredCardTabs from 'theme/components/theme/blocks/Account/StoredCardTabs'

export default {
  name: 'StoredCreditCards',
  components: {
    StoredCardTabs
  }
}
</script>

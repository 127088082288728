<template>
  <div class="address-book mb-5 px-xl pt-8.5 pb-8.5 bg-white">
    <!-- My newsletter header -->
    <div class="flex items-center justify-between mb-5">
      <div class="italic text-mid leading-h4 font-serif text-black tracking-tiny">
        {{ $t('Newsletter Subscription') }}
      </div>
    </div>
    <!-- My newsletter body (both modes) -->
    <div class="-ml-sm -mr-sm clearfix">
      <div class="w-1/2 pl-sm pr-sm mb-md float-left">
        <base-checkbox class="checkbox mr-sm" id="generalAgreement" v-model="user.isSubscribed" @click="user.isSubscribed = !user.isSubscribed">
          {{ $t('General Subscription') }}
        </base-checkbox>
      </div>
    </div>
    <div class="flex mt-lg mb-25px">
      <back-button class="mr-sm" />

      <button-full v-if="user.isSubscribed" class="save-button relative px-5 w-1/4" @click.native="subscribe(onSuccessfulSubmission, onFailureSubmission)">
        <span>{{ $t('Save') }}</span>
        <i class="ss-gizmo save-icon ss-right absolute text-h5"></i>
      </button-full>

      <button-full v-else class="save-button relative px-5 w-1/4" @click.native="unsubscribe(onSuccessfulUnsubscribe, onFailureUnsubscribe)">
        <span>{{ $t('Save') }}</span>
        <i class="ss-gizmo save-icon ss-right absolute text-h5"></i>
      </button-full>
    </div>
  </div>
</template>
<script>
import NewsletterSubscribe from '../../../../mixins/blocks/NewsletterSubscribe'
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseCheckbox from '../Form/BaseCheckbox'
import BackButton from './BackButton'
export default {
  components: {
    ButtonFull,
    BaseCheckbox,
    BackButton
  },
  mixins: [NewsletterSubscribe]
}

</script>

<style lang="scss" scoped>
.save-button {
  @apply px-5 text-left;
}

.save-icon {
  right: 20px;
  top: 18px;
}
</style>

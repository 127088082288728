<template>
  <div class="account">
    <div class="account__info mb-5 px-xl pt-8.5 pb-5 flex bg-black flex-wrap">
      <div class="w-full lg:w-1/2 mb-10 lg:mb-0 flex flex-col">
        <div class="account__name font-serif tracking-tiny text-xl leading-h4 text-white font-black">
          {{ currentUser.firstname }} {{ currentUser.lastname }}
        </div>
        <div class="account__email text-white text-heading-page leading-sm tracking-normal">
          {{ currentUser.email }}
        </div>
        <div class="mt-auto">
          <router-link :to="localizedRoute({name: 'my-profile'})" class="text-sm leading-none tracking-lg uppercase no-underline hover:underline text-white">
            {{ $t('Edit Contact Information') }}
          </router-link>
        </div>
      </div>
      <div class="w-full lg:w-1/2">
        <i class="account__level inline-block float-left" :class="`account__level--${currentTier}`" />
        <div class="account__grade pl-7 pr-xl">
          <p class="account__title uppercase">
            {{ $t('Current reward level') }}
          </p>
          <p class="account__head">
            {{ currentTier }}
          </p>
          <p class="account__title uppercase">
            {{ $t('Avg. monthly spend last quarter') }}
          </p>
          <p class="account__head">
            <span v-if="lastQtrSpend">{{ lastQtrSpend | price | numberDisplay }}</span>
          </p>
        </div>
      </div>
    </div>
    <account-next-level v-if="nextTierExists"/>
    <account-rewards />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AccountNextLevel from 'theme/components/theme/blocks/Account/AccountNextLevel'
import AccountRewards from 'theme/components/theme/blocks/Account/AccountRewards'

export default {
  name: 'AccountInfo',
  components: {
    AccountNextLevel,
    AccountRewards
  },
  data () {
    return {
      currentUser: Object.assign({}, this.$store.state.user.current)
    }
  },
  computed: {
    ...mapGetters({
      dashboardInfo: 'loyalty/getDashboard'
    }),
    lastQtrSpend () {
      return this.dashboardInfo ? this.dashboardInfo.last_qtr_avg_spend : 0
    },
    currentTier () {
      return this.dashboardInfo ? this.dashboardInfo.current_tier : ''
    },
    nextTierExists () {
      return this.dashboardInfo ? !!this.dashboardInfo.next_tier : false
    }
  },
  beforeMount () {
    this.$bus.$on('user-after-loggedin', this.onLoggedIn)
  },
  beforeDestroy () {
    this.$bus.$off('user-after-loggedin', this.onLoggedIn)
  },
  methods: {
    onLoggedIn () {
      this.currentUser = Object.assign({}, this.$store.state.user.current)
    }
  }
}
</script>

<style lang="scss" scoped>
.account {
  &__info {
    min-height: 218px;
  }

  &__grade {
    margin-left: 80px;
    @screen md {
      margin-left: 125px;
    }
  }

  &__level {
    background-image: url('/assets/sprite-rewards-level-hd.png');
    background-size: cover;
    height: 80px;
    width: 80px;

    @screen md {
      height: 125px;
      width: 125px;
    }

    &--gold {
      background-image: url('/assets/sprite-rewards-gold.png');
    }

    &--sapphire {
      background-position: 0 -80px;

      @screen md {
        background-position: 0 -125px;
      }
    }

    &--pearl {
      background-position: 0 0;

      @screen md {
        background-position: 0 0;
      }
    }

    &--emerald {
      background-position: 0 -160px;

      @screen md {
        background-position: 0 -250px;
      }
    }

    &--ruby {
      background-position: 0 -240px;

      @screen md {
        background-position: 0 -375px;
      }
    }

    &--diamond {
      background-position: 0 -320px;

      @screen md {
        background-position: 0 -500px;
      }
    }

    &--black_diamond {
      background-position: 0 -400px;

      @screen md {
        background-position: 0 -625px;
      }
    }
  }

  &__head {
    @apply text-white font-black text-lg leading-none tracking-md uppercase mb-6.5;
  }

  &__title {
    @apply text-sm leading-tight tracking-lg text-white uppercase;
    margin-bottom: 12px;
  }
}

</style>

<template>
  <div>
    <div v-if="isLoaded && dataLoaded">
      <button-outline @click.native="$router.go(-1)" class="-mb-4" v-if="isQuery">
        <i class="ss-gizmo ss-left text-sm"  />
        {{ $t('Back') }}
      </button-outline>
      <div class="flex justify-between items-center content-start">
        <h1 class="h1 mt-8 mb-4">Affiliate Dashboard</h1>
        <a v-if="affiliateId === (currentUser.extension_attributes || {}).affiliate_id"
          @click="singleSignOn"
           target="_blank"
           class="inline-block slow-color-change cursor-pointer whitespace-no-wrap outline-none focus:outline-none rounded-0 hover:bg-primary2 border-0 text-white text-center text-h5 uppercase tracking-sm w-full lg:w-auto reorder-button lg:mr-sm mb-2 lg:mb-0 bg-primary px-4 py-1 pl-5 pr-4">
          <span class="lg:text-sm text-h5">Login to Refersion</span>
          <i class="ss-gizmo ss-login inline-block align-middle ml-1 text-white"></i>
        </a>
      </div>

      <div class="">
        <div class="text-h4">ID: {{ seriesData.affiliate.id }}</div>
        <div class="text-h4">Name: {{ seriesData.affiliate.name }}</div>
        <div class="text-h4">Status: {{ seriesData.affiliate.status }}</div>
        <div class="text-h4" v-if="offerData">Profit Share: {{ offerData.name }}</div>
        <div class="text-h3 mt-5">
          <div class="text-h4 uppercase font-bold">Affiliate Link:</div> <input :readonly="true" autocomplete='off' spellcheck='false' autocorrect='off' class="py-1 px-2 w-full md:w-1/2" @click="copy" :value="`https://randco.com/${seriesData.affiliate.rfsn_parameter.replace('&', '?')}&utm_source=refersion&utm_medium=affiliate&utm_campaign=${seriesData.affiliate.rfsn_parameter.split('.')[0]}`"> <div class="text-h6 uppercase">Select Link to Copy</div>
        </div>
        <div class="text-h3 mt-5" v-if="seriesData.affiliate.vanity[0]">
          <div class="text-h4 uppercase font-bold">Vanity Link:</div> <input :readonly="true" autocomplete='off' spellcheck='false' autocorrect='off' class="py-1 px-2 w-full md:w-1/2" @click="copy" :value="`https://randco.love/${seriesData.affiliate.vanity[0].vanity}`"> <div class="text-h6 uppercase">Select Link to Copy</div>
        </div>
      </div>

      <h2 class="h2 mt-6 mb-4">Latest Sales</h2>

      <table class="table w-full mb-4" v-if="seriesData && ((seriesData.raw || {}).latest_sales || []).length">
        <thead>
        <tr>
          <th class="text-left">Date</th>
          <th class="text-left">Order #</th>
          <th class="text-left">Commission</th>
          <th class="text-left">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="sale in ((seriesData.raw || {}).latest_sales || [])" :key="sale.id">
          <td>{{ new Date(sale.created * 1000).toLocaleDateString() }}</td>
          <td v-html="sale.order_id || sale.notes.split('|').join(`<br>`)"></td>
          <td>${{ parseFloat(sale.commission_total).toFixed(2) }}</td>
          <td>{{ sale.payment_status }}</td>
        </tr>
        </tbody>
      </table>
      <div class="mb-4" v-else>
        No sales yet.
      </div>

      <!-- Latest Payouts -->
      <h2 class="h2 mt-6 mb-4">Latest Payouts</h2>

      <table class="table w-full mb-4" v-if="seriesData && ((seriesData.raw || {}).latest_payments || []).length">
        <thead>
        <tr>
          <th class="text-left">Payment ID</th>
          <th class="text-left">Date</th>
          <th class="text-left">Amount</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="payout in ((seriesData.raw || {}).latest_payments || [])" :key="payout.id">
          <td>{{ payout.payment_id }}</td>
          <td>{{ new Date(payout.created * 1000).toLocaleDateString() }}</td>
          <td>${{ parseFloat(payout.commission_total).toFixed(2) }}</td>
        </tr>
        </tbody>
      </table>
      <div class="mb-4" v-else>
        No payouts yet.
      </div>

      <h2 class="h1 mt-6 mb-4">Insights</h2>
      <div>
        <div class="text-sm uppercase">Legend</div>
        <span v-for="(color, name) in legend" :key="name" class="m-2">
          <span :style="{backgroundColor: color}" class="inline-block w-3 h-3 rounded-full mr-1"></span>
          {{ name.charAt(0).toUpperCase() + name.slice(1) }}
        </span>
      </div>

      <h3 class="h2 mt-6 mb-4">Lifetime Clicks & Conversions</h3>
      <div ref="chart-clicks"
           :style="{height: !seriesData || seriesData.clicks.length <= 0 ? 'auto' : '60vh'}">
        <div class="mb-4" v-if="!seriesData || seriesData.clicks.length <= 0">
          No data yet.
        </div>
      </div>

      <h3 class="h2 mt-6 mb-4">Lifetime Sales & Commission</h3>
      <div ref="chart-conversions"
           :style="{height: !seriesData || seriesData.sales.length <= 0 ? 'auto' : '60vh'}">
        <div class="mb-4" v-if="!seriesData || seriesData.sales.length <= 0">
          No data yet.
        </div>
      </div>

    </div>
    <div class="text-center" v-else-if="affiliateId">
        Loading... <loader color="text-black"/>
    </div>
    <div v-else>
      Please contact your account consultant to get access to your affiliate dashboard.<br>
      <a href="tel:+13056001305">+1 305-600-1305</a>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import config from 'config'
import {isServer} from '@vue-storefront/core/helpers'
import Loader from 'theme/components/theme/Loader'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import i18n from '@vue-storefront/core/i18n'

export default {
  name: 'AffiliateDetails',
  components: {ButtonOutline, Loader},
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      user: (state) => state.user
    })
  },
  data () {
    return {
      offerData: null,
      affiliateId: null,
      ssoToken: '',
      legend: {
        clicks: 'rgb(202,232,4)',
        conversions: 'rgba(4, 111, 232, 1)',
        commission: 'rgb(232, 4, 133)',
        sales: 'rgb(93,255,0)'
      },
      payoutData: [],
      salesData: [],
      isLoaded: false,
      dataLoaded: false,
      seriesData: {},
      chart: null,
      isQuery: false,
      offerList: [],
    }
  },
  mounted () {
    if (isServer) return

    // eslint-disable-next-line camelcase
    if (this.currentUser?.extension_attributes?.affiliate_id) {
      // eslint-disable-next-line camelcase
      this.affiliateId = this.currentUser?.extension_attributes?.affiliate_id
    }

    if (this.$route.query.id) {
      this.affiliateId = this.$route.query.id
      this.isQuery = true
    }

    if (!this.affiliateId) {
      return
    }

    fetch('https://randco.love/api/v1/offers').then((response) => {
      return response.json()
    }).then((data) => {
      this.offerList = data.map(x => {
        return {
          id: x.id,
          type: x.type,
          name: parseFloat(x.commission || 0).toFixed(0) + '%'
        }
      })
    }).catch((error) => {
      console.error(error)
    })

    this.loadScript('https://unpkg.com/lightweight-charts/dist/lightweight-charts.standalone.production.js').then(() => {
      this.isLoaded = true

      this.chart = window.LightweightCharts

      fetch(`${config?.affiliate?.baseUrl || 'https://randco.love'}/api/v1/affiliate-data/series-data`,
        {
          method: 'POST',
          credentials: 'omit',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: this.affiliateId })
        }
      ).then(x => {
        return x.json()
      }).then((response) => {
        this.seriesData = response
        this.dataLoaded = true

        this.$nextTick(() => {
          this.createCharts()
        })

        this.offerData = this.getOffer(this.seriesData.affiliate.offer_id)
      }).catch((error) => {
        console.error(error)
      })
    }).catch((error) => {
      console.error(error)
    })
  },
  methods: {
    getOffer (id) {
      return (this.offerList || []).find(x => x.id === id)
    },
    copy (e) {
      e.target.select()
      navigator.clipboard.writeText(e.target.value)
      this.$store.dispatch('notification/removeNotification', 0)

      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Copied!'),
        action1: { label: i18n.t('OK') }
      })
    },
    async singleSignOn () {
      // eslint-disable-next-line camelcase
      if (!this.user.token || !this.currentUser?.extension_attributes?.affiliate_id) {
        window.open('https://randco.refersion.com/affiliate/login', '_blank')
        return
      }

      fetch(`${config?.magento?.url || 'https://magento-b2b.luxbp.com'}` + `/rest/${config.defaultStoreCode}/V1/refersion-sso/mine`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.user.token
          },
          body: ''
        }
      ).then(res => {
        console.log(res)
        if (res.ok) {
          return res.json()
        }
        return null
      })
        .then((data) => {
          if (!data) {
            window.open('https://randco.refersion.com/affiliate/login', '_blank')
          }

          console.log(data)
          window.open('https://randco.refersion.com/affiliate/?sso_token=' + data, '_blank')
        }).catch((error) => {
          console.error(error)
          window.open('https://randco.refersion.com/affiliate/login', '_blank')
        })
    },
    async loadScript (FILE_URL, async = true, type = 'text/javascript') {
      return new Promise((resolve, reject) => {
        try {
          const scriptEle = document.createElement('script')

          scriptEle.type = type
          scriptEle.async = async
          scriptEle.src = FILE_URL

          scriptEle.addEventListener('load', () => {
            resolve({status: true})
          })

          scriptEle.addEventListener('error', () => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              status: false,
              message: `Failed to load the script ${FILE_URL}`
            })
          })

          document.body.appendChild(scriptEle)
        } catch (error) {
          reject(error)
        }
      })
    },
    createCharts () {
      const chart = window.LightweightCharts
      const opts = {
        autoSize: true
      }

      if (this.seriesData.clicks.length > 0 || this.seriesData.conversions.length > 0) {
        let chartData = chart.createChart(this.$refs['chart-clicks'], opts)

        chartData.addLineSeries({
          color: this.legend.clicks,
          lineWidth: 2
        }).setData(this.seriesData.clicks.map((item) => {
          return {
            time: item.time + '-02',
            value: parseFloat(item.value)
          }
        }))

        chartData.addLineSeries({
          color: this.legend.conversions,
          lineWidth: 2
        }).setData(this.seriesData.conversions.map((item) => {
          return {
            time: item.time + '-02',
            value: parseFloat(item.value)
          }
        }))

        chartData.timeScale().fitContent()
      }

      if (this.seriesData.commission.length > 0 || this.seriesData.sales.length > 0) {
        let chartsMonetary = chart.createChart(this.$refs['chart-conversions'], opts)

        chartsMonetary.addLineSeries({
          color: this.legend.commission,
          lineWidth: 2
        }).setData(this.seriesData.commission.map((item) => {
          return {
            time: item.time + '-02',
            value: parseFloat(item.value)
          }
        }))

        chartsMonetary.addLineSeries({
          color: this.legend.sales,
          lineWidth: 2
        }).setData(this.seriesData.sales.map((item) => {
          return {
            time: item.time + '-02',
            value: parseFloat(item.value)
          }
        }))

        chartsMonetary.timeScale().fitContent()
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"-mb-30px relative z-10"},[_c('h1',{staticClass:"text-primary text-heading-page heading--staggered leading-none"},[_vm._v("\n      "+_vm._s(_vm.id ? _vm.$t('Edit') : _vm.$t('Add New'))+"\n      "),_c('br'),_vm._v("\n      "+_vm._s(_vm.$t('Address'))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"p30px md:p-60px bg-white"},[_c('hr',{staticClass:"my-30px border-t border-grey-4"}),_vm._v(" "),_c('h2',{staticClass:"uppercase text-sm leading-sm tracking-2px"},[_vm._v("\n      "+_vm._s(_vm.$t('Contact Information'))+"\n    ")]),_vm._v(" "),_c('hr',{staticClass:"my-30px border-t border-grey-4"}),_vm._v(" "),_c('div',[_c('div',{staticClass:"flex flex-wrap"},[_c('base-input',{staticClass:"w-full md:w-1/2 md:pr-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"first-name","autocomplete":"given-name","placeholder":((_vm.$t('First name')) + " *"),"validations":[
            {
              condition: !_vm.$v.addressDetails.firstName.required && _vm.$v.addressDetails.firstName.$error,
              text: _vm.$t('Field is required')
            },
            {
              condition: !_vm.$v.addressDetails.firstName.minLength,
              text: _vm.$t('Name must have at least 2 letters.')
            }
          ]},on:{"input":function($event){return _vm.$v.addressDetails.firstName.$touch()}},model:{value:(_vm.addressDetails.firstName),callback:function ($$v) {_vm.$set(_vm.addressDetails, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.firstName"}},[_vm._v("\n          "+_vm._s(_vm.$t('First name'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])]),_vm._v(" "),_c('base-input',{staticClass:"w-full md:w-1/2 mt-4 md:mt-0 md:pl-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"last-name","autocomplete":"family-name","placeholder":((_vm.$t('Last name')) + " *"),"validations":[{
            condition: !_vm.$v.addressDetails.lastName.required && _vm.$v.addressDetails.lastName.$error,
            text: _vm.$t('Field is required')
          }]},on:{"input":function($event){return _vm.$v.addressDetails.lastName.$touch()}},model:{value:(_vm.addressDetails.lastName),callback:function ($$v) {_vm.$set(_vm.addressDetails, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.lastName"}},[_vm._v("\n          "+_vm._s(_vm.$t('Last name'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])])],1),_vm._v(" "),_c('base-input',{staticClass:"mt-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"company","autocomplete":"company","placeholder":_vm.$t('Company')},model:{value:(_vm.addressDetails.company),callback:function ($$v) {_vm.$set(_vm.addressDetails, "company", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.company"}},[_vm._v("\n        "+_vm._s(_vm.$t('Company'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-4"},[_c('base-input',{staticClass:"w-full md:w-1/2 md:pr-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"phone-number","autocomplete":"tel","placeholder":((_vm.$t('Telephone')) + " *"),"validations":[
            {
              condition: !_vm.$v.addressDetails.phone.required && _vm.$v.addressDetails.phone.$error,
              text: _vm.$t('Field is required')
            }
          ]},on:{"input":function($event){return _vm.$v.addressDetails.phone.$touch()}},model:{value:(_vm.addressDetails.phone),callback:function ($$v) {_vm.$set(_vm.addressDetails, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.phone"}},[_vm._v("\n          "+_vm._s(_vm.$t('Telephone'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])]),_vm._v(" "),_c('base-input',{staticClass:"w-full md:w-1/2 mt-4 md:mt-0 md:pl-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"fax-number","autocomplete":"fax","placeholder":_vm.$t('Fax')},model:{value:(_vm.addressDetails.fax),callback:function ($$v) {_vm.$set(_vm.addressDetails, "fax", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.fax"}},[_vm._v("\n          "+_vm._s(_vm.$t('Fax'))+"\n        ")])],1)],1),_vm._v(" "),_c('hr',{staticClass:"my-30px border-t border-grey-4"}),_vm._v(" "),_c('h2',{staticClass:"uppercase text-sm leading-sm tracking-2px"},[_vm._v("\n      "+_vm._s(_vm.$t('Address'))+"\n    ")]),_vm._v(" "),_c('hr',{staticClass:"my-30px border-t border-grey-4"}),_vm._v(" "),_c('div',[_c('base-input',{attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"street-address","autocomplete":"address-line1","placeholder":((_vm.$t('Street name')) + " *"),"validations":[{
          condition: !_vm.$v.addressDetails.street.required && _vm.$v.addressDetails.street.$error,
          text: _vm.$t('Field is required')
        }]},on:{"input":function($event){return _vm.$v.addressDetails.street.$touch()}},model:{value:(_vm.addressDetails.street),callback:function ($$v) {_vm.$set(_vm.addressDetails, "street", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.street"}},[_vm._v("\n        "+_vm._s(_vm.$t('Street Address'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])]),_vm._v(" "),_c('base-input',{staticClass:"mt-4",attrs:{"input-class":"bg-grey-5","type":"text","name":"apartment-number","autocomplete":"address-line2","placeholder":((_vm.$t('House/Apartment number')) + " *"),"validations":[{
          condition: !_vm.$v.addressDetails.house.required && _vm.$v.addressDetails.house.$error,
          text: _vm.$t('Field is required')
        }]},on:{"input":function($event){return _vm.$v.addressDetails.house.$touch()}},model:{value:(_vm.addressDetails.house),callback:function ($$v) {_vm.$set(_vm.addressDetails, "house", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.house"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-4"},[_c('base-input',{staticClass:"w-full md:w-1/2 md:pr-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"city","autocomplete":"address-level2","placeholder":((_vm.$t('City')) + " *"),"validations":[{
            condition: !_vm.$v.addressDetails.city.required && _vm.$v.addressDetails.city.$error,
            text: _vm.$t('Field is required')
          }]},on:{"input":function($event){return _vm.$v.addressDetails.city.$touch()}},model:{value:(_vm.addressDetails.city),callback:function ($$v) {_vm.$set(_vm.addressDetails, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.city"}},[_vm._v("\n          "+_vm._s(_vm.$t('City'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])]),_vm._v(" "),_c('base-input',{staticClass:"w-full md:w-1/2 mt-4 md:mt-0 md:pl-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"state","autocomplete":"address-level1","placeholder":((_vm.$t('State / Province')) + " *"),"validations":[{
            condition: !_vm.$v.addressDetails.region.required && _vm.$v.addressDetails.region.$error,
            text: _vm.$t('Field is required')
          }]},on:{"input":function($event){return _vm.$v.addressDetails.region.$touch()}},model:{value:(_vm.addressDetails.region),callback:function ($$v) {_vm.$set(_vm.addressDetails, "region", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.region"}},[_vm._v("\n          "+_vm._s(_vm.$t('State / Province'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])])],1),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-4"},[_c('base-input',{staticClass:"w-full md:w-1/2 md:pr-4",attrs:{"input-class":"bg-grey-5 mt-2","type":"text","name":"zip-code","autocomplete":"postal-code","placeholder":((_vm.$t('Zip / Postal code')) + " *"),"validations":[
            {
              condition: !_vm.$v.addressDetails.postcode.required && _vm.$v.addressDetails.postcode.$error,
              text: _vm.$t('Field is required')
            },
            {
              condition: !_vm.$v.addressDetails.postcode.minLength,
              text: _vm.$t('Zip-code must have at least 3 letters.')
            }
          ]},on:{"input":function($event){return _vm.$v.addressDetails.postcode.$touch()}},model:{value:(_vm.addressDetails.postcode),callback:function ($$v) {_vm.$set(_vm.addressDetails, "postcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addressDetails.postcode"}},[_vm._v("\n          "+_vm._s(_vm.$t('Zip / Postal code'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])]),_vm._v(" "),_c('base-select',{staticClass:"w-full md:w-1/2 mt-4 md:mt-0 md:pl-4",attrs:{"input-class":"bg-grey-5 mt-2","name":"countries","options":_vm.countryOptions,"selected":_vm.addressDetails.country,"placeholder":_vm.$t('Country *'),"validations":[
            {
              condition: _vm.$v.addressDetails.country.$error && !_vm.$v.addressDetails.country.required,
              text: _vm.$t('Field is required')
            }
          ],"autocomplete":"country-name"},on:{"blur":function($event){return _vm.$v.addressDetails.country.$touch()},"change":function($event){return _vm.$v.addressDetails.country.$touch()}},model:{value:(_vm.addressDetails.country),callback:function ($$v) {_vm.$set(_vm.addressDetails, "country", $$v)},expression:"addressDetails.country"}},[_vm._v("\n          "+_vm._s(_vm.$t('Country'))),_c('span',{staticClass:"text-red-2"},[_vm._v("*")])])],1),_vm._v(" "),_c('base-checkbox',{staticClass:"mt-4",attrs:{"id":"is-shipping-address","disabled":_vm.isShipping},on:{"click":function($event){_vm.useAsShipping = !_vm.useAsShipping}},model:{value:(_vm.useAsShipping),callback:function ($$v) {_vm.useAsShipping=$$v},expression:"useAsShipping"}},[_vm._v("\n        "+_vm._s(_vm.isShipping ? _vm.$t('Default shipping address') : _vm.$t('Use as my default shipping address'))+"\n      ")]),_vm._v(" "),_c('base-checkbox',{staticClass:"mt-4",attrs:{"id":"is-billing-address","disabled":_vm.isBilling},on:{"click":function($event){_vm.useAsBilling = !_vm.useAsBilling}},model:{value:(_vm.useAsBilling),callback:function ($$v) {_vm.useAsBilling=$$v},expression:"useAsBilling"}},[_vm._v("\n        "+_vm._s(_vm.isBilling ? _vm.$t('Default billing address') : _vm.$t('Use as my default billing address'))+"\n      ")])],1),_vm._v(" "),_c('hr',{staticClass:"mt-16 mb-2 border-t border-grey-10"}),_vm._v(" "),_c('p',{staticClass:"text-right text-red-2 mb-3"},[_vm._v("\n      * "+_vm._s(_vm.$t('Required Fields'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex"},[_c('back-button'),_vm._v(" "),_c('button-outline',{staticClass:"ml-auto",attrs:{"disabled":_vm.$v.$invalid},nativeOn:{"click":function($event){return _vm.updateDetails($event)}}},[_vm._v("\n        "+_vm._s(_vm.$t('Save Address'))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
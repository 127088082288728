<template>
  <div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Events & Classes') }}
    </h2>
    <account-class-tickets :tickets="tickets" />
  </div>
</template>

<script>
import AccountClassTickets from './AccountClassTickets'
export default {
  name: 'ClassTickets',
  components: {
    AccountClassTickets
  },
  data () {
    return {
      tickets: {
        title: 'R+Co Legends Tour San Francisco',
        items: [
          {
            quantity: 'Ticket 1',
            email: 'digital@luxbp.com',
            key: 'DD7B5CB49447BC2CCB949EEBF8396F9C',
            name: 'LBP Technology'
          },
          {
            quantity: 'Ticket 2',
            email: 'digital@luxbp.com',
            key: '0D818757E25A138069B37E638318985B',
            name: 'LBP Technology'
          }
        ]
      }
    }
  }
}
</script>

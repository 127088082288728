var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"main-form-wrapper"},[(!_vm.formData.offer)?_c('div',{staticClass:"main-form-overlay"}):_vm._e(),_vm._v(" "),_c('button-outline',{staticClass:"-mb-4",nativeOn:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"ss-gizmo ss-left text-sm"}),_vm._v("\n        "+_vm._s(_vm.$t('Back'))+"\n      ")]),_vm._v(" "),_c('h2',{staticClass:"text-primary text-h2 content-title mb-3 pb-3 mt-6"},[_vm._v("\n        "+_vm._s('Stylist Registration')+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-select',{staticClass:"col-xs-12 col-sm-6 mb25",attrs:{"name":"offer","id":'offer',"options":_vm.offerOptions,"placeholder":'Select Stylist Profit Share Percentage',"validations":[
              {
                condition: _vm.$v.formData.offer.$error && !_vm.$v.formData.offer.required,
                text: _vm.validateMessage('required', { attr: 'Profit Share'})
              }
            ]},model:{value:(_vm.formData.offer),callback:function ($$v) {_vm.$set(_vm.formData, "offer", $$v)},expression:"formData.offer"}},[_vm._v("\n            Profit Share "),_c('span',[_vm._v("*")])])],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('base-input',{attrs:{"input-class":'text-black',"placeholder":'First Name',"autocomplete":"given-name","name":"first-name","type":"text","validations":[
                {
                  condition: _vm.$v.formData.first_name.$error && !_vm.$v.formData.first_name.required,
                  text: _vm.validateMessage('required', { attr: 'First name'})
                },
                {
                  condition: !_vm.$v.formData.first_name.minLength,
                  text: _vm.validateMessage('minLength', { attr: 'First name', min: 2 })
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.first_name.$touch()}},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}},[_vm._v("\n            First name "),_c('span',[_vm._v("*")])])],1),_vm._v(" "),_c('div',{staticClass:"w-full md:w-1/2 px-3"},[_c('base-input',{attrs:{"input-class":'text-black',"placeholder":'Last Name',"autocomplete":"family-name","name":"last-name","type":"text","validations":[
                {
                  condition: _vm.$v.formData.last_name.$error && !_vm.$v.formData.last_name.required,
                  text: _vm.validateMessage('required', { attr: 'Last name'})
                },
                {
                  condition: !_vm.$v.formData.last_name.minLength,
                  text: _vm.validateMessage('minLength', { attr: 'Last name', min: 2 })
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.last_name.$touch()}},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}},[_vm._v("\n            Last name "),_c('span',[_vm._v("*")])])],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full px-3"},[_c('base-input',{attrs:{"input-class":'text-black',"placeholder":'Email',"autocomplete":"email","name":"email","type":"email","validations":[
                {
                  condition: _vm.$v.formData.email.$error && !_vm.$v.formData.email.required,
                  text: _vm.validateMessage('required', { attr: 'Email' })
                },
                {
                  condition: !_vm.$v.formData.email.email,
                  text: _vm.validateMessage('email', { attr: 'Email' })
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.email.$touch()}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}},[_vm._v("\n            Email "),_c('span',[_vm._v("*")])])],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-2 -mx-3"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('base-input',{attrs:{"input-class":'text-black',"placeholder":'Password',"name":"password","type":"password","validations":[
                {
                  condition: _vm.$v.formData.password.$error && !_vm.$v.formData.password.required,
                  text: _vm.validateMessage('required', { attr: 'Password' })
                },
                {
                  condition: _vm.$v.formData.password.$error && !_vm.$v.formData.password.minLength,
                  text: _vm.validateMessage('minLength', { attr: 'Password', min: 9 })
                },
                {
                  condition: _vm.$v.formData.password.$error && !_vm.$v.formData.password.containsUppercase,
                  text: 'Password must contain at least one uppercase letter.'
                },
                {
                  condition: _vm.$v.formData.password.$error && !_vm.$v.formData.password.containsLowercase,
                  text: 'Password must contain at least one lowercase letter.'
                },
                {
                  condition: _vm.$v.formData.password.$error && !_vm.$v.formData.password.containsNumber,
                  text: 'Password must contain at least one number.'
                },
                {
                  condition: _vm.$v.formData.password.$error && !_vm.$v.formData.password.containsSpecial,
                  text: 'Password must contain at least one special character.'
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.password.$touch()},"change":function($event){return _vm.$v.formData.password.$touch()}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}},[_vm._v("\n            "+_vm._s('Password')+" "),_c('span',[_vm._v("*")])])],1),_vm._v(" "),_c('div',{staticClass:"w-full md:w-1/2 px-3"},[_c('base-input',{attrs:{"input-class":'text-black',"placeholder":'Password Confirmation',"name":"password_confirmation","type":"password","validations":[
                {
                  condition: _vm.$v.formData.password_confirmation.$error && !_vm.$v.formData.password_confirmation.required,
                  text: _vm.validateMessage('required', { attr: 'Password Confirmation' })
                },
                {
                  condition: !_vm.$v.formData.password_confirmation.sameAsPassword && _vm.$v.formData.password_confirmation.$error,
                  text: 'Passwords must be identical.'
                }
              ]},on:{"blur":function($event){return _vm.$v.formData.password_confirmation.$touch()},"change":function($event){return _vm.$v.formData.password_confirmation.$touch()}},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}},[_vm._v("\n            "+_vm._s('Password Confirmation')+" "),_c('span',[_vm._v("*")])])],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap -mx-3 my-6"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('a',{staticClass:"block text-black mb-3 uppercase underline text-md",attrs:{"target":"_blank","href":"https://www.randco.com/pages/affiliates-legal"}},[_vm._v("Click here to read terms and conditions")]),_vm._v(" "),_c('base-checkbox',{attrs:{"id":"terms","validations":[
                {
                  condition: _vm.$v.formData.custom_67398.$error && !_vm.$v.formData.custom_67398.sameAs,
                  text: 'You must agree to the terms and conditions.'
                }
              ]},on:{"click":function($event){_vm.formData.custom_67398 = !_vm.formData.custom_67398},"blur":function($event){return _vm.$v.formData.custom_67398.$touch()},"change":function($event){return _vm.$v.formData.custom_67398.$touch()}},model:{value:(_vm.formData.custom_67398),callback:function ($$v) {_vm.$set(_vm.formData, "custom_67398", $$v)},expression:"formData.custom_67398"}},[_vm._v("\n            "+_vm._s('Agree to terms and conditions')+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"w-full md:w-1/2 px-3"})]),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('button-with-icon',{attrs:{"type":'submit',"color":'primary',"icon-class":"ss-right","loadin":_vm.formLoading}},[_vm._v("\n          "+_vm._s('Submit')+"\n        ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
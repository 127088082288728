var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("My Reseller Certificates")]),_vm._v(" "),_c('form',{ref:"form"},[_c('base-select',{staticClass:"location mb-sm",attrs:{"name":"location","options":_vm.getShippingLocations,"selected":_vm.formData.netsuite_id,"validations":_vm.$v.formData.netsuite_id
              ? [
                  {
                    condition:
                      _vm.$v.formData.netsuite_id.$error &&
                      !_vm.$v.formData.netsuite_id.required,
                    text: _vm.validateMessage('required', { attr: 'Location' }),
                  }
                ]
              : []},model:{value:(_vm.formData.netsuite_id),callback:function ($$v) {_vm.$set(_vm.formData, "netsuite_id", $$v)},expression:"formData.netsuite_id"}},[_vm._v("\n      "+_vm._s(_vm.$t('Location:'))+"\n    ")]),_vm._v(" "),_c('base-input',{attrs:{"tabindex":"17","input-class":'text-black',"placeholder":"Reseller Certificate","name":"reseller_certificate","type":"file","accept":".pdf, .png, .jpg, .jpeg, .tiff","validations":_vm.$v.formData.reseller_certificate
              ? [
                  {
                    condition:
                      _vm.$v.formData.reseller_certificate.$error &&
                      !_vm.$v.formData.reseller_certificate.required,
                    text: _vm.validateMessage('required', { attr: 'Reseller Certificate' }),
                  }
                ]
              : []},on:{"change":_vm.setFile},model:{value:(_vm.formData.reseller_certificate),callback:function ($$v) {_vm.$set(_vm.formData, "reseller_certificate", $$v)},expression:"formData.reseller_certificate"}},[_vm._v("\n      "+_vm._s(_vm.$t('Upload Certificate:'))+"\n    ")]),_vm._v(" "),_c('button',{attrs:{"type":"submit"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v("Upload")])],1),_vm._v(" "),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2')])}]

export { render, staticRenderFns }
<template>
  <div class="next-level bg-white mb-5" v-show="false">
    <div class="px-xl pt-8.5 pb-11 flex flex-wrap">
      <div class="flex-auto w-full lg:w-1/2 mb-10 lg:mb-0 flex flex-col">
        <div class="italic text-mid leading-h4 font-serif text-black tracking-tiny md:mb-15 mb-4">
          {{ $t('Achieve the next rewards level') }}
        </div>
        <div class="next-level__head font-black leading-none tracking-normal mb-sm text-black uppercase">
          {{ qtrSpend | price }}
        </div>
        <div class="font-black text-h5 leading-loose tracking-mid text-black uppercase">
          {{ $t('Your avg. monthly spend this quarter') }}
        </div>
        <div class="text-h5 leading-loose tracking-mid text-black uppercase">
          {{ spendPeriod }}
        </div>
      </div>
      <div class="flex-auto w-full lg:w-1/2 flex">
        <div class="next-le__grade">
          <div class="next-level__label lg:text-right">
            <span class="text-average leading-none tracking-xs text-dark">
              {{ $t('Updated')}}
            </span>
            <span class="text-average leading-none tracking-xs text-dark">
              {{ updatedDate | moment("MMM DD [at] h:mm A") }}
            </span>
          </div>
          <div class="flex items-center">
            <div class="next-level__level">
              <div class="next-level__image flex-shrink-0" :class="`next-level__image--${milestoneTier.common_name}`" />
            </div>
            <div class="next-level__side leading-h6 pl-xl">
              <span class="text-h5 font-bold leading-h6 tracking-mid text-dark uppercase">
                {{ $t('You are currently on track to qualify for') }}
              </span>
              <span class="text-h5 font-bold leading-h6 tracking-mid text-grey-34 uppercase underline">{{ milestoneTier.common_name | capitalize }}</span>
              <span class="text-h5 font-bold leading-h6 tracking-mid text-dark uppercase">{{ $t('next quarter.') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="milestone flex border-t border-solid border-grey-21" v-if="dashboardInfo.milestone_spend > 0">
      <div class="flex-auto w-1/2 flex items-center border-r border-solid border-grey-21 px-xl pb-xl pt-7">
        <div class="milestone__level hidden md:block">
          <div class="milestone__image" :class="`milestone__image--${milestoneTier.common_name}`" />
        </div>
        <div class="md:pl-4">
          <div class="milestone__info">
            <span class="font-bold text-sm leading-h6 tracking-mid uppercase text-black">
              {{ $t('Your next rewards milestone is') }}
            </span>
            <span class="font-bold text-sm leading-h6 tracking-mid uppercase text-blue-2 underline">
              {{ milestoneTier.common_name | capitalize }}
            </span>
          </div>
          <div class="milestone__target uppercase font-black text-heading-page leading-none tracking-md text-black">
            <span>{{ $t('Spend') }}</span>
            <span>{{ dashboardInfo.milestone_spend | price | numberDisplay }}</span>
            <span>{{ $t('more') }}</span>
          </div>
        </div>
      </div>
      <div class="flex-auto w-1/2 flex p-xl">
        <div class="milestone__icon mt-sm hidden md:block">
          <i class="ss-gizmo ss-clock text-lg"></i>
        </div>
        <div class="milestone__time md:pl-7">
          <div class="font-bold text-sm leading-h6 tracking-mid uppercase text-black">
            {{ $t('Time left to qualify') }}
          </div>
          <div class="uppercase font-black text-h3 tracking-mid text-black">
            {{ dashboardInfo.milestone_days_left }} {{ $t('days') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'theme/filters/moment'

export default {
  name: 'AccountNextLevel',
  filters: {
    moment
  },
  computed: {
    monthes () {
      return [
        this.$t('Jan'),
        this.$t('Feb'),
        this.$t('Mar'),
        this.$t('Apr'),
        this.$t('May'),
        this.$t('Jun'),
        this.$t('Jul'),
        this.$t('Aug'),
        this.$t('Sep'),
        this.$t('Oct'),
        this.$t('Nov'),
        this.$t('Dec')
      ]
    },
    ...mapGetters({
      loyaltyCustomer: 'loyalty/getCustomer',
      milestoneTier: 'loyalty/getMilestoneTier',
      dashboardInfo: 'loyalty/getDashboard'
    }),
    qtrSpend () {
      return this.dashboardInfo ? this.dashboardInfo.qtr_avg_spend : 0
    },
    updatedDate () {
      return this.loyaltyCustomer ? this.loyaltyCustomer.updated_at : ''
    },
    spendPeriod () {
      if (!this.dashboardInfo) {
        return ''
      }

      const quarter = this.dashboardInfo.dates.quarter
      const quarterStart = new Date(this.dashboardInfo.dates.quarter_start)
      const quarterEnd = new Date(this.dashboardInfo.dates.quarter_end)

      return `(Q${quarter} - ${this.monthes[quarterStart.getMonth()]} ${quarterStart.getDate()} - ${this.monthes[quarterEnd.getMonth()]} ${quarterEnd.getDate()})`
    }
  }
}
</script>

<style lang="scss" scoped>
.next-level {
  &__head {
    font-size: 53px;
  }

  &__label {
    margin-bottom: 43px;
  }

  &__image {
    background-image: url('/assets/sprite-rewards-level-hd.png');
    background-size: cover;
    height: 68px;
    width: 68px;

    @screen md {
      height: 125px;
      width: 125px;
    }

    $position: -68px;
    $position-md: -125px;

    &--gold {
      background-image: url('/assets/sprite-rewards-gold.png');
    }

    &--perl {
      background-position: 0 ($position * 0);

      @screen md {
        background-position: 0 ($position-md * 0);
      }
    }

    &--sapphire {
      background-position: 0 ($position * 1);

      @screen md {
        background-position: 0 ($position-md * 1);
      }
    }

    &--emerald {
      background-position: 0 ($position * 2);

      @screen md {
        background-position: 0 ($position-md * 2);
      }
    }

    &--ruby {
      background-position: 0 ($position * 3);

      @screen md {
        background-position: 0 ($position-md * 3);
      }
    }

    &--diamond {
      background-position: 0 ($position * 4);

      @screen md {
        background-position: 0 ($position-md * 4);
      }
    }

    &--black_diamond {
      background-position: 0 ($position * 5);

      @screen md {
        background-position: 0 ($position-md * 5);
      }
    }
  }

  &__side {
    @screen xl {
      @apply pr-20;
    }
  }
}

.milestone {
  &__image {
    background-image: url('/assets/sprite-rewards-level-hd.png');
    background-size: 39px auto;
    width: 39px;
    height: 39px;

    $position: -39px;

    &--gold {
      background-image: url('/assets/sprite-rewards-gold.png');
    }

    &--perl {
      background-position: 0 ($position * 0);
    }

    &--sapphire {
      background-position: 0 ($position * 1);
    }

    &--emerald {
      background-position: 0 ($position * 2);
    }

    &--ruby {
      background-position: 0 ($position * 3);
    }

    &--diamond {
      background-position: 0 ($position * 4);
    }

    &--black_diamond {
      background-position: 0 ($position * 5);
    }
  }
  &__level {
    padding-top: 8px;
  }
}
</style>

<template>
<div>
  <div class="account__info mb-8">
    <div class="account__info__block flex items-center">
      <h1 class="account__title uppercase w-full md:w-2/3 md:text-h2 text-h3 leading-h3">
        {{ $t('Stylists Manager') }}
      </h1>
      <span class="account__head w-full md:w-1/3 justify-end text-right">
        <button-with-icon icon-class="ss-plus" :small="true" @click.native="createRegistrationForm">
          {{ $t('Add Stylist') }}
        </button-with-icon>
      </span>
    </div>
  </div>

  <div v-if="stylists && stylists.length > 0" class="text-left overflow-x-scroll w-full">
    <table class="table table-auto max-w-full">
      <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Share</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="stylist in stylists" :key="stylist.id" :class="{'opacity-50': stylist.status !== 'ACTIVE' }">
        <td>{{ stylist.name }}</td>
        <td>{{ stylist.email }}</td>
        <td>{{ getOffer(stylist.offer_id).name }}</td>
        <td>
          <div class="flex items-center align-center justify-evenly">
            <button-with-icon class="mb-2" icon-class="ss-right" :small="true" @click.native="viewReport(stylist.id)">
              {{ $t('Details') }}
            </button-with-icon>
            <button-with-icon class="mb-2" icon-class="ss-right" :loading="isLoading(stylist.id)" :color="stylist.status === 'ACTIVE' ? 'red-2' : 'green-2'"  :small="true" @click.native="setStatus(stylist.id, !(stylist.status === 'ACTIVE'))">
              <span v-if="stylist.status === 'ACTIVE'">{{ $t('Disable') }}</span>
              <span v-else>{{ $t('Enable') }}</span>
            </button-with-icon>
            <div style="height: 28px; width: 28px" class="inline-block bg-black hover:bg-primary2 text-base text-center cursor-pointer overflow-hidden mb-2 pt-1" @click="isEditing(stylist.id) ? removeEditing(stylist.id) : addEditing(stylist.id)">
              <i v-if="!isLoading(stylists.id, 'offer_loading')" class="ss-gizmo text-white" :class="isEditing(stylist.id) ? 'ss-delete' : 'ss-write'"></i>
              <loader color="text-white" v-else />
            </div>
          </div>
          <base-select v-if="isEditing(stylist.id)" class="mt-1" input-class="py-1" :options="offerList.map(x => ({label: x.name, value: x.id}))" placeholder="Select profit share..." :selected="stylist.offer_id" @input="updateOffer($event, stylist.id)">
            <template v-slot:default>
              Edit Profit Share
            </template>
          </base-select>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div v-else-if="isLoaded && !stylists.length">
    <p class="text-center">
      {{ $t('No stylists found, try adding some.') }}
    </p>
  </div>
  <div v-else-if="noId">
    <p class="text-center">
      {{ $t('Please contact your account consultant to get access to your affiliate dashboard. +1 305-600-1305') }}
    </p>
  </div>
  <div v-else>
    <p class="text-center">
      {{ $t('Loading...') }} <loader color="text-black"/>
    </p>
  </div>

  <div class="register-form">
    <iframe id="mountable">
      <html>
        <body>
        <div id="#mountPoint-affiliate"></div>
        </body>
      </html>
    </iframe>

  </div>
</div>
</template>

<script>
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import Loader from 'theme/components/theme/Loader'
import {mapState} from 'vuex'
import config from 'config'
import {isServer} from '@vue-storefront/core/helpers'
import i18n from '@vue-storefront/core/i18n'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import debounce from 'lodash-es/debounce'

export default {
  name: 'AffiliateStylist',
  components: {BaseSelect, Loader, ButtonWithIcon},
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      user: (state) => state.user
    })
  },
  data () {
    return {
      registerInstance: null,
      registerScriptLoaded: false,
      registerCreated: false,
      isLoaded: false,
      stylists: [],
      offerList: [],
      noId: false,
      loading: [],
      edit_visibility: [],
      offer_loading: []
    }
  },
  methods: {
    updateOffer: debounce(function (offerId, stylistId) {
      let self = this

      self.removeEditing(stylistId)
      self.addLoading(stylistId, 'offer_loading')

      fetch(`${config.api.url}/api/ext/vsf-refersion-app/${stylistId}/offer`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: stylistId,
            offer: offerId
          })
        }
      ).then(res => res.json()).then((data) => {
        fetch('https://randco.love/api/v1/register/fetch-account-data', {
          method: 'POST',
          body: JSON.stringify({
            id: stylistId
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(() => {
          self.removeLoading(stylistId, 'offer_loading')
          self.getStylists()
        })
      }).catch((error) => {
        self.addEditing(stylistId)
        self.removeLoading(stylistId, 'offer_loading')
        console.error(error)
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Error updating stylist offer.'),
          action1: { label: i18n.t('OK'), action: 'close' }
        })

      })
    }, 500),
    addEditing (id) {
      this.edit_visibility.push(id)
    },
    removeEditing (id) {
      this.edit_visibility = this.edit_visibility.filter(x => x !== id)
    },
    isEditing (id) {
      return this.edit_visibility.includes(id)
    },
    isLoading (stylistId, type) {
      this[type] || (this[type] = [])
      return this[type || 'loading'].includes(stylistId)
    },
    addLoading (id, type) {
      this[type] || (this[type] = [])
      this[type || 'loading'].push(id)
    },
    removeLoading (id, type) {
      this[type] || (this[type] = [])
      this[type || 'loading'] = this.loading.filter(x => x !== id)
    },
    createRegistrationForm () {
      this.$router.push({ name: 'stylists-form' })
    },
    viewReport (id) {
      this.$router.push({ name: 'affiliate-details', query: { id: id } })
    },
    setStatus (id, status) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t(`Are you sure you would like ${status ? 'enable' : 'disable'} this stylist?`),
        action2: {
          label: i18n.t('OK'),
          action: () => {
            this.addLoading(id)
            fetch(`${config.api.url}/api/ext/vsf-refersion-app/${id}/status`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  id: id,
                  status: status
                })
              }
            ).then(res => res.json()).then((data) => {
              fetch('https://randco.love/api/v1/register/fetch-account-data', {
                method: 'POST',
                body: JSON.stringify({
                  id: id
                }),
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(() => {
                this.getStylists()
                this.removeLoading(id)
              })
            }).catch((error) => {
              this.removeLoading(id)
              console.error(error)
            })
          }
        },
        action1: { label: i18n.t('Cancel'), action: 'close' },
        hasNoTimeout: true
      })
    },
    getOffer (id) {
      return (this.offerList || []).find(x => x.id === id) || { name: 'Error mapping, contact admin.' }
    },
    getStylists () {
      this.isLoaded = false
      fetch('https://randco.love/api/v1/register/list-sub-accounts',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.currentUser.extension_attributes.affiliate_id
          })
        }
      ).then(res => res.json()).then((data) => {
        this.stylists = data
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.isLoaded = true
      })
    }
  },
  mounted () {
    // get stylist data
    if (isServer) return

    // eslint-disable-next-line camelcase
    if (!this.currentUser?.extension_attributes?.affiliate_id) {
      this.noId = true
      return
    }

    fetch('https://randco.love/api/v1/register/offer-links').then((response) => {
      return response.json()
    }).then((data) => {
      this.offerList = data.map(x => {
        return {
          id: x.offer.id,
          name: parseFloat(x.offer.commission || 0).toFixed(0) + '%'
        }
      })

      this.getStylists()
    }).catch((error) => {
      console.error(error)
    })
  }
}
</script>

<style scoped lang="scss">
iframe#mountable {
  display: block;
  margin: 0 auto;
  width: 95%;
  height: 150vh;
  overflow: visible;
}

.justify-evenly {
  justify-content: space-evenly;
}
</style>

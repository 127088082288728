<template>
  <div class="rewards mb-5 px-5 lg:px-xl pt-5 pb-5 lg:pt-8.5 lg:pb-15 flex flex-wrap bg-white">
    <div class="w-full lg:w-1/2 mb-5 lg:mb-0">
      <div class="italic text-mid leading-h4 font-serif text-black tracking-tiny mb-5 lg:mb-10">
        {{ $t('Your Rewards') }}
      </div>
      <div class="rewards__table">
        <div v-for="(reward, i) in rewardPoints" :key="i" class="">
          <div v-if="reward.hasPermission || reward.hasSubBrandPermission" class="flex">
            <div class="rewards__column w-5/12 uppercase font-bold text-h5 leading-none text-right border-t" :class="{ 'border-b': (i === rewardPoints.length - 1), 'text-white': (i > 1) }" :style="bankNameStyles(reward || {})">
              {{ reward.name }}
            </div>
            <div class="rewards__column bg-grey-39 w-7/12 text-h4 leading-none text-brown-2 border-t border-r" :class="{ 'border-b': (i === rewardPoints.length - 1) }" :style="bankPointsStyles(reward || {})">
              {{ reward.balance }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-1/2" v-if="false">
      <div class="rewards__actions flex flex-col h-full lg:pl-15 justify-end items-start">
        <button-full :link="localizedRoute({ name: 'cart' })" class="rewards__button py-xs lg:w-auto w-full relative">
          <span class="text-sm">{{ $t('Redeem your points') }}</span>
          <i class="ss-gizmo checkout-icon ss-right text-h5 absolute right-10px"></i>
        </button-full>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import { mapGetters } from 'vuex'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'

export default {
  name: 'AccountRewards',
  computed: {
    ...mapGetters({
      rewards: 'loyalty/getCustomerAccounts',
      dashboardAccounts: 'loyalty/getDashboardAccounts'
    })
  },
  components: {
    ButtonFull
  },
  mixins: [
    EarnedPoints
  ],
  data () {
    return {
      rewardPoints: null,
      isAccountMenu: false
    }
  },
  async beforeMount () {
    this.rewardPoints = await this.getRewards()
  },
  methods: {
    bankNameStyles (bank) {
      return {
        backgroundColor: '#' + bank.color,
        borderColor: '#' + bank.color
      }
    },
    bankPointsStyles (bank) {
      return {
        borderColor: '#' + bank.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rewards {
  &__column {
    padding: 13px 5px;
    @screen md {
      padding: 13px 24px;
    }
  }

  &__button {
    @apply pl-5 pr-8 py-3;

    @screen lg {
      @apply text-sm py-1;
    }

    &::v-deep {
      span {
        @apply flex items-center text-h5;

        @screen lg {
          @apply text-sm;
        }
      }

      i {
        @apply ml-xs;
        margin-top: 2px;
      }
    }
  }
}
</style>

<template>
  <div class="relative">
    <label
      v-if="hasDefaultSlot"
      class="label uppercase text-black text-h6 tracking-md leading-thick whitespace-no-wrap"
    >
      <slot />
    </label>
    <textarea
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :class="[{'has-error pr-8' : (isValid === false)},inputClass]"
      :name="name"
      :ref="focus ? name : false"
      :value="value"
      :placeholder="placeholder"
      :rows="rows"
      @blur="$emit('blur')"
      @input="$emit('input', $event.target.value)"
      @keyup="$emit('keyup', $event)"
      @keyup.enter="$emit('keyup.enter', $event.target.value)"
      class="block text-dark py-sm px-5 focus:outline-none focus:bg-grey-11 border border-solid border-dark text-average leading-sm tracking-xs w-full"
    />
    <ValidationMessages :validations="validations" v-if="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages'
import Validation from 'theme/mixins/forms/Validation'

export default {
  name: 'BaseTextarea',
  mixins: [Validation],
  components: {
    ValidationMessages
  },
  data () {
    return {
      iconActive: false,
      icon: 'visibility'
    }
  },
  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  props: {
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    rows: {
      type: [Number],
      required: false,
      default: 3
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  textarea {
    min-height: 100px;
  }

  .has-error {
    border-bottom-width: 2px;
    border-bottom-color: #e81212;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-grey-13;
  }

  input::placeholder,
  textarea::placeholder {
    @apply text-grey-13;
  }
</style>

<script>

import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import {httpClient} from 'theme/helpers/httpClient'
import validateMessage from 'theme/helpers/valdiateMessage'
import { validationMixin } from 'vuelidate'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import {isServer} from '@vue-storefront/core/helpers'
import {mapState} from 'vuex'
import i18n from '@vue-storefront/core/i18n'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  name: 'AffiliateStylistForm',
  components: {ButtonOutline, BaseCheckbox, BaseSelect, BaseInput, ButtonWithIcon},
  mounted () {
    if (isServer) return

    if (!((this.currentUser || {}).extension_attributes || {}).affiliate_id) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Your account is not associated with an affiliate account!'),
        action1: { label: i18n.t('OK') }
      })

      this.$router.push({ name: 'affiliate-stylists' })
      return
    }

    this.formData.status = 'ACTIVE'
    this.formData.company = this.currentUser.company || this.currentUser.firstname + ' ' + this.currentUser.lastname
    this.formData.unique_merchant_id = this.currentUser.extension_attributes.affiliate_id

    fetch('https://randco.love/api/v1/register/offer-links').then((response) => {
      return response.json()
    }).then((data) => {
      if (data && !data.length) return
      this.offers.offers = {}
      data.forEach((x) => {
        let name = parseFloat(x.offer.commission || 0).toFixed(2) + '% Share'

        this.offers.offers[name] = x.offer.id
      })

      this.offers.offers = Object.keys(this.offers.offers).sort().reduce(
        (obj, key) => {
          obj[key] = this.offers.offers[key]
          return obj
        },
        {}
      )
    }).catch((error) => {
      console.error('Error:', error)
    })
  },
  data () {
    return {
      formLoading: false,
      offers: {
        'types': {
        },
        'offers': {
        }
      },
      current: false,
      submitted: false,
      countries: {
        'us': 'United States',
        'ca': 'Canada'
      },
      usaStates: {
        'AL': 'Alabama',
        'AK': 'Alaska',
        'AS': 'American Samoa',
        'AZ': 'Arizona',
        'AR': 'Arkansas',
        'CA': 'California',
        'CO': 'Colorado',
        'CT': 'Connecticut',
        'DE': 'Delaware',
        'DC': 'District Of Columbia',
        'FM': 'Federated States Of Micronesia',
        'FL': 'Florida',
        'GA': 'Georgia',
        'GU': 'Guam',
        'HI': 'Hawaii',
        'ID': 'Idaho',
        'IL': 'Illinois',
        'IN': 'Indiana',
        'IA': 'Iowa',
        'KS': 'Kansas',
        'KY': 'Kentucky',
        'LA': 'Louisiana',
        'ME': 'Maine',
        'MH': 'Marshall Islands',
        'MD': 'Maryland',
        'MA': 'Massachusetts',
        'MI': 'Michigan',
        'MN': 'Minnesota',
        'MS': 'Mississippi',
        'MO': 'Missouri',
        'MT': 'Montana',
        'NE': 'Nebraska',
        'NV': 'Nevada',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NY': 'New York',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'MP': 'Northern Mariana Islands',
        'OH': 'Ohio',
        'OK': 'Oklahoma',
        'OR': 'Oregon',
        'PW': 'Palau',
        'PA': 'Pennsylvania',
        'PR': 'Puerto Rico',
        'RI': 'Rhode Island',
        'SC': 'South Carolina',
        'SD': 'South Dakota',
        'TN': 'Tennessee',
        'TX': 'Texas',
        'UT': 'Utah',
        'VT': 'Vermont',
        'VI': 'Virgin Islands',
        'VA': 'Virginia',
        'WA': 'Washington',
        'WV': 'West Virginia',
        'WI': 'Wisconsin',
        'WY': 'Wyoming'
      },
      canadaStates: {
        'AB': 'Alberta',
        'BC': 'British Columbia',
        'MB': 'Manitoba',
        'NB': 'New Brunswick',
        'NL': 'Newfoundland and Labrador',
        'NT': 'Northwest Territories',
        'NS': 'Nova Scotia',
        'NU': 'Nunavut',
        'ON': 'Ontario',
        'PE': 'Prince Edward Island',
        'QC': 'Quebec',
        'SK': 'Saskatchewan',
        'YT': 'Yukon Territory'
      },
      followerOptions: [
        {
          label: '0 - 5,000',
          value: '0 - 5,000'
        },
        {
          label: '5,000 - 10,000',
          value: '5,000 - 10,000'
        },
        {
          label: '20,000 - 50,000',
          value: '20,000 - 50,000'
        },
        {
          label: '50,000+',
          value: '50,000+'
        }
      ],
      formData: {
        status: 'DISABLED',
        offer: '',
        first_name: '',
        last_name: '',
        company: '',
        phone: '',
        email: '',
        address1: '',
        address2: '',
        zip: '',
        postal_code: '',
        country: '',
        state: '',
        password: '',
        password_confirmation: '',
        unique_merchant_id: '',
        paypal_email: '',
        'custom_67534': '', // Salon Name
        'custom_67394': '', // Distributor Name
        'custom_67395': '', // Distributor Sales Consultant Name
        'custom_55362': '', // Instagram
        'custom_67390': '', // Number of IG Followers
        'custom_68111': '', // Tiktok Handle
        'custom_68112': '', // Tiktok followers
        'custom_68652': '', // Website
        'custom_67398': false // Agree to terms
      }
    }
  },
  validations () {
    let validationsData = {
      formData: {
        offer: {
          required
        },
        first_name: {
          required,
          minLength: minLength(2)
        },
        last_name: {
          required,
          minLength: minLength(2)
        },
        password: {
          required,
          minLength: minLength(9),
          containsUppercase: function(value) {
            return /[A-Z]/.test(value)
          },
          containsLowercase: function(value) {
            return /[a-z]/.test(value)
          },
          containsNumber: function(value) {
            return /[0-9]/.test(value)
          },
          containsSpecial: function(value) {
            return /[#?!@$%^&*-]/.test(value)
          }
        },
        password_confirmation: {
          required,
          sameAsPassword: sameAs('password')
        },
        email: {
          required,
          email,
          minLength: minLength(5)
        },
        custom_67398: { // terms
          sameAs: sameAs(() => true)
        }
      }
    }

    return validationsData
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      user: (state) => state.user
    }),
    companyRequiredString: function () {
      return (this.$v.formData.company ? '*' : '')
    },
    countryOptions: function () {
      return Object.keys(this.countries).map((el) => { return { label: this.countries[el], value: el } })
    },
    usaStateOptions: function () {
      return Object.keys(this.usaStates).map((el) => { return { label: this.usaStates[el], value: el } })
    },
    canadaStateOptions: function () {
      return Object.keys(this.canadaStates).map((el) => { return { label: this.canadaStates[el], value: el } })
    },
    offerOptions: function () {
      let data = this.offers || {}
      let results = []

      for (const key in (data.offers || {})) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.offers.hasOwnProperty(key)) {
          results.push({
            label: key, value: data.offers[key]
          })
        }
      }
      return results
    },
    getOfferType: function () {
      let data = this.offers || {}

      for (const key in (data.types || {})) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.types.hasOwnProperty(key)) {
          if (data.types[key].includes(this.formData.offer)) {
            return key
          }
        }
      }
      return null
    },
    isSalon: function () {
      return this.getOfferType === 'salon' ||
        this.getOfferType === 'salon_direct' ||
        this.getOfferType === 'distributor'
    }
  },
  methods: {
    redirect (id) {
      if (id && isNaN(id)) {
        let rfsn = new URL(id).searchParams.get('rfsn')

        id = rfsn.split('.')[0]
      }

      fetch('https://randco.love/api/v1/register/fetch-account-data', {
        method: 'POST',
        body: JSON.stringify({
          id: id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json()
      }).then((data) => {
        if (data && data.id) {
          this.$router.push({ name: 'affiliate-stylists' })

          this.$store.dispatch('notification/spawnNotification', {
            type: 'success',
            message: i18n.t('Stylist account has been created!')
          })
        }
      }).catch((error) => {
        this.$router.push({ name: 'affiliate-stylists' })

        this.$store.dispatch('notification/spawnNotification', {
          type: 'warn',
          message: i18n.t('Stylist account has been created, but may take some time to display.'),
          action1: { label: i18n.t('OK') }
        })
      })
    },
    submit () {
      this.$v.$touch()

      let formData = Object.assign({}, this.formData)

      if (formData.country === 'us') {
        formData['state-' + formData.country] = formData.state
      }

      let bodyFormData = new FormData()

      bodyFormData.append('first_name', this.formData.company + ' - ')
      bodyFormData.append('last_name', this.formData.first_name + ' ' + this.formData.last_name)
      bodyFormData.append('company', this.formData.company + ' - ' + this.formData.first_name)

      for (let key in formData) {
        if (formData[key] === null || formData[key] === '' || bodyFormData.has(key)) continue
        bodyFormData.append(key, formData[key])
      }



      const params = new URLSearchParams(window.location.search)
      const test = params.get('test')

      if (test) {
        bodyFormData.append('test', true)
      }

      if (this.$v.$invalid) {
        // Notification
        this.$v.$touch()
        this.scrollToError()
      } else {
        // this.submitted = true
        this.formLoading = true
        httpClient.post('https://randco.love/api/v1/registerAdvance', bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-System-Key': '$2a$13$zxfJacGpZzH9ymF2hfFJMuZBAJPFYzYFK/UL7eR6eeWkiS/nUVhJq'
          }
        }).then((response) => {
          this.formLoading = false
          console.log(response)
          // handle success
          if (response.data.id) {
            this.submitted = true
            window.scrollTo(0, 0)
            this.redirect(response.data.link)
          }
        }).catch((error) => {
          this.formLoading = false
          if (error.response && error.response.data && error.response.data.errors) {
            let errors = []

            for (let i = 0; i < error.response.data.errors.length; i++) {
              errors.push(error.response.data.errors[i])
            }

            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: errors.join('<br>'),
              action1: { label: i18n.t('OK') }
            })
            return
          }

          if (error.response && error.response.data) {
            if (error.response.data.error) {
              this.$store.dispatch('notification/spawnNotification', {
                type: 'error',
                message: error.response.data.error,
                action1: { label: i18n.t('OK') }
              })
              return
            }

            let errors = []

            for (const key in error.response.data) {
              errors.push(key + ': ' + error.response.data[key])
            }

            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: errors.join('<br>'),
              action1: { label: i18n.t('OK') }
            })
            return
          }

          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t('There was an error creating the stylist account!'),
            action1: { label: i18n.t('OK') }
          })
        })
      }
    },
    scrollToError () {
      setTimeout(() => {
        let errors = document.querySelectorAll('.errorMessage')

        if (errors && errors.length) {
          let top = window.pageYOffset + errors[0].getBoundingClientRect().top - 300

          window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth'
          })
        }
      }, 100)
    }
  },
  mixins: [validateMessage, validationMixin]
}
</script>

<template>
<div>
  <form @submit.prevent="submit">
    <div class="main-form-wrapper">
      <div class="main-form-overlay" v-if="!formData.offer" />
      <button-outline @click.native="$router.go(-1)" class="-mb-4">
        <i class="ss-gizmo ss-left text-sm"  />
        {{ $t('Back') }}
      </button-outline>
      <h2 class="text-primary text-h2 content-title mb-3 pb-3 mt-6">
        {{ 'Stylist Registration' }}
      </h2>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-select
            class="col-xs-12 col-sm-6 mb25"
            name="offer"
            :id="'offer'"
            :options="offerOptions"
            :placeholder="'Select Stylist Profit Share Percentage'"
            v-model="formData.offer"
            :validations="[
              {
                condition: $v.formData.offer.$error && !$v.formData.offer.required,
                text: validateMessage('required', { attr: 'Profit Share'})
              }
            ]"
          >
            Profit Share <span>*</span>
          </base-select>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <base-input
            :input-class="'text-black'"
            :placeholder="'First Name'"
            autocomplete="given-name"
            name="first-name"
            type="text"
            v-model="formData.first_name"
            @blur="$v.formData.first_name.$touch()"
            :validations="[
                {
                  condition: $v.formData.first_name.$error && !$v.formData.first_name.required,
                  text: validateMessage('required', { attr: 'First name'})
                },
                {
                  condition: !$v.formData.first_name.minLength,
                  text: validateMessage('minLength', { attr: 'First name', min: 2 })
                }
              ]"
          >
            First name <span>*</span>
          </base-input>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <base-input
            :input-class="'text-black'"
            :placeholder="'Last Name'"
            autocomplete="family-name"
            name="last-name"
            type="text"
            v-model="formData.last_name"
            @blur="$v.formData.last_name.$touch()"
            :validations="[
                {
                  condition: $v.formData.last_name.$error && !$v.formData.last_name.required,
                  text: validateMessage('required', { attr: 'Last name'})
                },
                {
                  condition: !$v.formData.last_name.minLength,
                  text: validateMessage('minLength', { attr: 'Last name', min: 2 })
                }
              ]"
          >
            Last name <span>*</span>
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full px-3">
          <base-input
            :input-class="'text-black'"
            :placeholder="'Email'"
            autocomplete="email"
            name="email"
            v-model="formData.email"
            @blur="$v.formData.email.$touch()"
            type="email"
            :validations="[
                {
                  condition: $v.formData.email.$error && !$v.formData.email.required,
                  text: validateMessage('required', { attr: 'Email' })
                },
                {
                  condition: !$v.formData.email.email,
                  text: validateMessage('email', { attr: 'Email' })
                }
              ]"
          >
            Email <span>*</span>
          </base-input>
        </div>
      </div>
      <div class="flex flex-wrap mt-2 -mx-3">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <base-input
            :input-class="'text-black'"
            :placeholder="'Password'"
            name="password"
            @blur="$v.formData.password.$touch()"
            @change="$v.formData.password.$touch()"
            v-model="formData.password"
            type="password"
            :validations="[
                {
                  condition: $v.formData.password.$error && !$v.formData.password.required,
                  text: validateMessage('required', { attr: 'Password' })
                },
                {
                  condition: $v.formData.password.$error && !$v.formData.password.minLength,
                  text: validateMessage('minLength', { attr: 'Password', min: 9 })
                },
                {
                  condition: $v.formData.password.$error && !$v.formData.password.containsUppercase,
                  text: 'Password must contain at least one uppercase letter.'
                },
                {
                  condition: $v.formData.password.$error && !$v.formData.password.containsLowercase,
                  text: 'Password must contain at least one lowercase letter.'
                },
                {
                  condition: $v.formData.password.$error && !$v.formData.password.containsNumber,
                  text: 'Password must contain at least one number.'
                },
                {
                  condition: $v.formData.password.$error && !$v.formData.password.containsSpecial,
                  text: 'Password must contain at least one special character.'
                }
              ]"
          >
            {{ 'Password' }} <span>*</span>
          </base-input>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <base-input
            :input-class="'text-black'"
            :placeholder="'Password Confirmation'"
            name="password_confirmation"
            @blur="$v.formData.password_confirmation.$touch()"
            @change="$v.formData.password_confirmation.$touch()"
            v-model="formData.password_confirmation"
            type="password"
            :validations="[
                {
                  condition: $v.formData.password_confirmation.$error && !$v.formData.password_confirmation.required,
                  text: validateMessage('required', { attr: 'Password Confirmation' })
                },
                {
                  condition: !$v.formData.password_confirmation.sameAsPassword && $v.formData.password_confirmation.$error,
                  text: 'Passwords must be identical.'
                }
              ]"
          >
            {{ 'Password Confirmation' }} <span>*</span>
          </base-input>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 my-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <a class="block text-black mb-3 uppercase underline text-md" target="_blank" href="https://www.randco.com/pages/affiliates-legal">Click here to read terms and conditions</a>
          <base-checkbox
            id="terms"
            @click="formData.custom_67398 = !formData.custom_67398"
            @blur="$v.formData.custom_67398.$touch()"
            @change="$v.formData.custom_67398.$touch()"
            v-model="formData.custom_67398"
            :validations="[
                {
                  condition: $v.formData.custom_67398.$error && !$v.formData.custom_67398.sameAs,
                  text: 'You must agree to the terms and conditions.'
                }
              ]"
          >
            {{ 'Agree to terms and conditions' }}
          </base-checkbox>
        </div>
        <div class="w-full md:w-1/2 px-3" />
      </div>
      <div class="mt-2">
        <button-with-icon :type="'submit'" :color="'primary'" icon-class="ss-right" :loadin="formLoading">
          {{ 'Submit' }}
        </button-with-icon>
      </div>
    </div>
  </form>
</div>
</template>

<style scoped lang="scss">

</style>

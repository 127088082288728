<template>
  <div>
    <div class="px-xl pt-8.5 pb-11 bg-white">
      <h2 class="italic text-mid leading-h4 font-serif text-black tracking-tiny mb-5 font-normal">
        {{ $t('Account Information') }}
      </h2>
      <div class="w-2/3 mb-5">
        <span>
          {{ $t('To edit your first name, last name or email address please contact your account consultant:') }}
        </span>
        <a href="tel:305-600-1305" class="text-brown-2 hover:underline">305-600-1305</a>
      </div>
      <div class="flex flex-wrap">
        <base-input
          class="w-full md:w-1/2 md:pr-2"
          input-class="mt-1"
          type="text"
          name="first-name"
          autocomplete="given-name"
          :placeholder="$t('First name')"
          v-model.trim="currentUser.firstname"
          @input="$v.currentUser.firstname.$touch()"
          :validations="[
            {
              condition: !$v.currentUser.firstname.required,
              text: $t('Field is required')
            },
            {
              condition: !$v.currentUser.firstname.minLength,
              text: $t('Name must have at least 2 letters.')
            }
          ]"
        >
          <span class="text-md">*</span> {{ $t('First Name') }}
        </base-input>

        <base-input
          class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-2"
          input-class="mt-1"
          type="text"
          name="last-name"
          autocomplete="family-name"
          :placeholder="$t('Last name')"
          v-model.trim="currentUser.lastname"
          @input="$v.currentUser.lastname.$touch()"
          :validations="[{
            condition: !$v.currentUser.lastname.required,
            text: $t('Field is required')
          }]"
        >
          <span class="text-md">*</span> {{ $t('Last Name') }}
        </base-input>
      </div>

      <base-input
        class="mt-4"
        input-class="mt-1"
        type="email"
        name="email-address"
        autocomplete="email"
        :placeholder="$t('Email address')"
        v-model="currentUser.email"
        :validations="[
          {
            condition: !$v.currentUser.email.required,
            text: $t('Field is required')
          },
          {
            condition: !$v.currentUser.email.email,
            text: $t('Please provide valid e-mail address.')
          }
        ]"
      >
        <span class="text-md">*</span> {{ $t('Email Address') }}
      </base-input>

      <!-- Change password (edit mode) -->
      <base-checkbox
        class="mt-4"
        id="changePassword"
        v-model="changePassword"
        @click="changePassword = !changePassword"
      >
        {{ $t('Change password') }}
      </base-checkbox>

      <template v-if="changePassword">
        <base-input
          class="w-full md:w-1/2 mt-4 md:pr-2"
          input-class="mt-1"
          type="password"
          name="old-password"
          autocomplete="current-password"
          :placeholder="$t('Current password *')"
          v-model="oldPassword"
          @input="$v.oldPassword.$touch()"
          :validations="[{
            condition: !$v.oldPassword.required && $v.oldPassword.$error,
            text: $t('Field is required')
          }]"
        >
          <span class="text-md">*</span> {{ $t('Current password') }}
        </base-input>
        <div class="flex flex-wrap mt-4">
          <base-input
            class="w-full md:w-1/2 md:pr-2"
            input-class="mt-1"
            type="password"
            name="password"
            autocomplete="new-password"
            :placeholder="$t('New password *')"
            v-model="password"
            @input="$v.password.$touch()"
            :validations="[{
              condition: !$v.password.required && $v.password.$error,
              text: $t('Field is required')
            }]"
          >
            <span class="text-md">*</span> {{ $t('New password') }}
          </base-input>

          <base-input
            class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-2"
            input-class="mt-1"
            type="password"
            name="password-confirm"
            autocomplete="new-password"
            :placeholder="$t('Confirm new password *')"
            v-model="rPassword"
            @input="$v.rPassword.$touch()"
            :validations="[
              {
                condition: !$v.rPassword.required && $v.rPassword.$error,
                text: $t('Field is required')
              },
              {
                condition: !$v.rPassword.sameAsPassword,
                text: $t('Passwords must be identical.')
              }
            ]"
          >
            <span class="text-md">*</span> {{ $t('Confirm New password') }}
          </base-input>
        </div>
      </template>

      <div class="flex mt-10 mb-3">
        <back-button  class="mr-sm" />

        <button-full
          class="save-btn relative w-1/4"
          @click.native="updateProfile"
          :disabled="checkValidation()"
        >
          <span>{{ $t('Save') }}</span>
          <i class="ss-gizmo save-icon ss-right absolute text-h5 right-20px top-50%"></i>
        </button-full>
      </div>
      <p class="uppercase tracking-md text-h6 leading-none">
        * {{ $t('Required Fields') }}
      </p>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import MyProfile from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyProfile'

import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BackButton from './BackButton'

export default {
  components: {
    BaseCheckbox,
    BaseInput,
    BackButton,
    ButtonFull
  },
  mixins: [MyProfile],
  methods: {
    checkValidation () {
      if (this.changePassword) {
        return this.$v.currentUser.$invalid || this.$v.password.$invalid || this.$v.rPassword.$invalid
      } else {
        return this.$v.currentUser.$invalid
      }
    }
  },
  validations: {
    currentUser: {
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      }
    },
    oldPassword: {
      required
    },
    password: {
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>

<style lang="scss" scoped>
.save-btn {
  @apply px-5 text-left;
}

.save-icon {
  transform: translate(0, -50%);
}
</style>

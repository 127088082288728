import { Newsletter } from 'src/modules/vsf-klaviyo/components/Newsletter'
import i18n from '@vue-storefront/i18n'

export default {
  name: 'NewsletterSubscribe',
  mixins: [ Newsletter ],
  methods: {
    onSuccessfulSubmission () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('You have been successfully subscribed to our newsletter!'),
        action1: { label: i18n.t('OK') }
      })
    },
    onFailureSubmission () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('We\'re unable to subscribe you to our newsletter at this time.'),
        action1: { label: i18n.t('OK') }
      })
    },
    subscribe (success, failure) {
      if (!this.user.isSubscribed) return
      this.checkStatus(response => {
        this.user.isSubscribed = response
        if (!this.user.isSubscribed) {
          this.$store.dispatch('klaviyo/subscribe', this.email).then(res => {
            if (success) {
              success(res)
              this.email = ''
            }
          }).catch(err => {
            if (failure) failure(err)
          })
        } else {
          this.notificationIfUserWasSubscribed()
        }
      }, failure => {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('We\'ve encountered an unknown error. Please try this action again later.'),
          action1: { label: i18n.t('OK') }
        })
      })
    },
    onSuccessfulUnsubscribe (success) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('You\'ve been successfully removed from our newsletter.'),
        action1: { label: i18n.t('OK') }
      })
    },
    onFailureUnsubscribe (failure) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('You\'re not subscribed to our newsletter.'),
        action1: { label: i18n.t('OK') }
      })
    },
    unsubscribe (success, failure) {
      if (this.user.isSubscribed) return
      this.$store.dispatch('klaviyo/unsubscribe', this.email).then(res => {
        if (success) {
          success(res)
        }
      }).catch(err => {
        if (failure) failure(err)
      })
    },
    notificationIfUserWasSubscribed () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('You are already subscribed!'),
        action1: { label: i18n.t('OK') }
      })
    }
  }
}

<template>
  <div class="order-pagination">
    <div class="lg:flex mb-sm justify-between">
      <div v-if="orderTotal" class="mb-md lg:mb-0">
        <span>{{ `Items ${from}`}}</span>
        <span>{{ `to ${to}` }}</span>
      </div>
      <div class="lg:flex items-center">
        <span class="mr-sm text-h4 leading-base tracking-mid hidden lg:inline">{{ $t('Show') }}</span>
        <base-select name="showBy" :options="showBy" input-class="uppercase order-pagination__select" caret-size="icon-xs"
          :selected="perPage" v-model="perPage" />
        <span class="ml-sm text-h4 leading-base tracking-mid hidden lg:inline">{{ $t('per page') }}</span>
      </div>
    </div>
    <div class="flex row items-center leading-none py-4 px-2 justify-center" :class="[paginationClass]">
      <span class="mr-sm text-above-md lg:text-h4 leading-base tracking-mid sr-only">{{ $t('Page:') }}</span>
      <a href="#" class="mr-sm no-underline text-grey-32 hover:text-black" :class="{ 'opacity-25': currentPage === 1 }" @click.prevent="currentPage === 1 ? null : prevPage()">
        <svg class="icon-lg text-black fill-current">
          <use xlink:href="/assets/icons.svg#full-arrow-left" />
        </svg>
        <span class="inline-block text-black text-md mr-md">
          Newer
        </span>
      </a>
      <span class="pagination-page text-above-md lg:text-average tracking-xs">
        <span class="inline-block text-black mr-sm text-md">
          {{ currentPage }}
        </span>
      </span>
      <a href="#" class="no-underline" :class="{ 'opacity-25': !hasMoreOrders }" @click.prevent="!hasMoreOrders ? null : nextPage()">
        <span class="inline-block text-black mr-sm ml-md text-md">
          Older
        </span>
        <svg class="icon-lg text-black fill-current">
          <use xlink:href="/assets/icons.svg#full-arrow-right" />
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
import {
  mapGetters, mapState
} from 'vuex'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'

export default {
  name: 'OrdersPagination',
  data () {
    return {
      showBy: [{
        label: '25',
        value: '25'
      },
      {
        label: '50',
        value: '50'
      },
      {
        label: '100',
        value: '100'
      }
      ]
    }
  },
  components: {
    BaseSelect
  },
  props: {
    paginationClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapState({
      orderHistoryPayload: state => state.user.orders_history
    }),
    totalPages () {
      let total = this.orderHistoryPayload.total_count

      return Math.ceil(total / this.perPage)
    },
    hasMoreOrders () {
      return this.currentPage < this.totalPages
    },
    from () {
      return ((this.currentPage - 1) * this.perPage) + 1
    },
    to () {
      return ((this.currentPage - 1) * this.perPage) + this.orderTotal
    },
    perPage: {
      get () {
        return this.$store.state.myaccount.perPage
      },
      set (value) {
        this.$emit('perPageChange', value)
      }
    },
    currentPage () {
      return this.$store.state.myaccount.current
    },
    ...mapGetters({
      ordersHistory: 'user/getOrdersHistory'
    }),
    orderTotal () {
      return this.ordersHistory.length
    }
  },
  methods: {
    prevPage () {
      this.$emit('prevPage')
    },
    nextPage () {
      this.$emit('nextPage')
    }
  }
}

</script>
<style lang="scss">
.hideElement {
  visibility: hidden;
}
.order-pagination {
  &__select {
    @apply bg-grey-27 border-grey-21 normal-case;
    @screen lg {
      @apply py-1 bg-white;
    }
  }
}

</style>

<template>
  <div>
    <header class="-mb-30px relative z-10">
      <h1 class="text-primary text-heading-page heading--staggered leading-none">
        {{ $t('My') }}
        <br>
        {{ $t('Wishlist') }}
      </h1>
    </header>

    <div class="p-30px md:p-60px bg-white">
      <h2 class="uppercase text-sm leading-sm tracking-2px">
        {{ $t('My Wishlist') }}
      </h2>
      <hr class="my-30px border-t border-grey-4">

      <div v-if="productsInWishlist.length">
        <product v-for="product in productsInWishlist" :key="product.id" :product="product" />
      </div>
      <p v-else>
        {{ $t('You have no items in your wishlist.') }}
      </p>

      <hr class="mt-16 mb-2 border-t border-grey-10">

      <div>
        <back-button />
      </div>
    </div>
  </div>
</template>

<script>
import { Wishlist } from '@vue-storefront/core/modules/wishlist/components/Wishlist'
import Product from 'theme/components/core/blocks/Wishlist/Product'
import BackButton from './BackButton'

export default {
  components: {
    Product,
    BackButton
  },
  mixins: [Wishlist],
  methods: {
  }
}
</script>

<template>
  <div class="rewards-level mb-5">
    <div class="-ml-sm -mr-sm flex flex-wrap">
      <div class="w-full lg:w-1/3 pl-sm pr-sm flex">
        <div class="w-full flex flex-col rewards-level__wrapper pt-8.5 pb-5 px-13 text-center" :class="`rewards-level__wrapper--${currentTier}`">
          <div class="rewards-level__title lg:italic text-above-md lg:text-mid leading-h4 font-bold tracking-lg lg:font-serif text-white lg:tracking-tiny lg:mb-13 uppercase lg:capitalize">
            {{ $t('Rewards level') }}
          </div>
          <div class="mb-auto font-black text-white text-big leading-none tracking-tight uppercase">
            {{ currentTier }}
          </div>
          <div class="text-white font-black text-h5 leading-loose tracking-mid uppercase hidden lg:block">
            {{ $t('Avg. monthly spend last quarter') }}
          </div>
          <div class="text-white text-h5 leading-loose tracking-mid uppercase mb-sm hidden lg:block">
            {{ lastQtrSpendPeriod }}
          </div>
          <div class="text-white font-black text-big leading-none tracking-tight uppercase mb-10 hidden lg:block">
            {{ lastQtrSpend | price }}
          </div>
          <router-link
            :to="localizedRoute('/rewards-program/reward-levels')"
            class="rewards-level__link text-white">
            {{ $t('About reward levels.') }}
          </router-link>
        </div>
      </div>
      <div class="w-full lg:w-2/3 pl-sm pr-sm">
        <div class="accordion">
          <div class="accordion__main bg-white" v-for="(account, index) in customerAccountsFiltered" :key="index">
            <div class="accordion__head cursor-pointer relative" @click="toggle(index)">
              <div class="accordion__title uppercase font-bold text-sm tracking-mid text-black leading-none">
                {{ account.bank.name }} {{ $t('points % earning') }}
              </div>
              <div class="accordion__discount text-h3 uppercase font-bold tracking-mid text-black leading-none">
                {{ account.tier.earning_percentage }}%
              </div>
              <div class="accordion__icon absolute" :class="{'accordion__icon--expanded': index !== activeIndex}"/>
            </div>
            <div
              class="accordion__content"
              :class="{'accordion__content--expanded': index === activeIndex}"
            >
              {{ $t('Earn points on eligible wholesale purchases. The more you spend, the more points you earn to redeem for products! Check your point balances now in the account summary section.') }}
            </div>
          </div>
          <div class="accordion__main bg-white" :key="customerAccounts.length" v-if="false">
            <div class="accordion__head cursor-pointer relative" @click="toggle(customerAccounts.length)">
              <div class="accordion__title uppercase font-bold text-sm tracking-mid text-black leading-none">
                {{ $t('Education discount') }}
              </div>
              <div v-if="dashboardInfo" class="accordion__discount text-h3 uppercase font-bold tracking-mid text-black leading-none">
                {{ (dashboardInfo.current_tier_details || {}).edu_discount_percentage }}%
              </div>
              <div class="accordion__icon absolute" :class="{'accordion__icon--expanded': customerAccounts.length !== activeIndex}"/>
            </div>
            <div
              class="accordion__content"
              :class="{'accordion__content--expanded': customerAccounts.length === activeIndex}"
            >
              {{ $t('Enjoy the percentage discount above on any education you purchase in cash. This applies to in-salon, regional, and LBP Studios based classes. View the education calendar now to explore exciting education from LBP and our portfolio of brands.') }}
            </div>
          </div>
          <div class="accordion__main bg-white" :key="customerAccounts.length + 1" v-if="false">
            <div class="accordion__head cursor-pointer relative" @click="toggle(customerAccounts.length + 1)">
              <div class="accordion__title uppercase font-bold text-sm tracking-mid text-black leading-none">
                {{ $t('Web referral profit share') }}
              </div>
              <div v-if="dashboardInfo && dashboardInfo.current_tier_details" class="accordion__discount text-h3 uppercase font-bold tracking-mid text-black leading-none">
                {{ (dashboardInfo.current_tier_details || {}).webprofit_percentage }}%
              </div>
              <div class="accordion__icon absolute" :class="{'accordion__icon--expanded': customerAccounts.length + 1 !== activeIndex}"/>
            </div>
            <div
              class="accordion__content"
              :class="{'accordion__content--expanded': customerAccounts.length + 1 === activeIndex}"
            >
              {{ $t('Earn this percentage share on the profit generated by any web sales you refer using your affiliate code. Get your referral links from the Web Profit Share section of your account or click here to learn more about Web Profit Sharing.') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrandPermission from 'theme/mixins/blocks/BrandPermission'

export default {
  name: 'RewardsLevel',
  mixins: [BrandPermission],
  data () {
    return {
      activeIndex: null
    }
  },
  computed: {
    ...mapGetters({
      dashboardInfo: 'loyalty/getDashboard',
      customerAccounts: 'loyalty/getCustomerAccounts'
    }),
    customerAccountsFiltered () {
      return this.customerAccounts.filter(x => this.hasBrandPermissionByCode(x.bank.code))
    },
    currentTier () {
      return this.dashboardInfo ? this.dashboardInfo.current_tier : ''
    },
    monthes () {
      return [
        this.$t('Jan'),
        this.$t('Feb'),
        this.$t('Mar'),
        this.$t('Apr'),
        this.$t('May'),
        this.$t('Jun'),
        this.$t('Jul'),
        this.$t('Aug'),
        this.$t('Sep'),
        this.$t('Oct'),
        this.$t('Nov'),
        this.$t('Dec')
      ]
    },
    lastQtrSpend () {
      return this.dashboardInfo ? this.dashboardInfo.last_qtr_avg_spend : 0
    },
    lastQtrSpendPeriod () {
      if (!this.dashboardInfo) {
        return ''
      }

      const quarter = this.dashboardInfo.dates.quarter
      const quarterStart = new Date(this.dashboardInfo.dates.quarter_start)
      const quarterEnd = new Date(this.dashboardInfo.dates.quarter_end)

      return `(Q${quarter} - ${this.monthes[quarterStart.getMonth()]} ${quarterStart.getDate()} - ${this.monthes[quarterEnd.getMonth()]} ${quarterEnd.getDate()})`
    }
  },
  methods: {
    toggle (index) {
      this.activeIndex === index
        ? this.activeIndex = null
        : this.activeIndex = index
    }
  },
  mounted () {
    this.$store.dispatch('loyalty/loyaltyTiers')
  }
}
</script>

<style lang="scss" scoped>
.rewards-level {
  &__wrapper {
    background-size: cover;
    &--gold {
      background-image: url('/assets/gold.jpg');
    }

    &--sapphire {
      background-image: url('/assets/sapphire.jpg');
    }

    &--pearl {
      background-image: url('/assets/pearl.jpg');
    }

    &--emerald {
      background-image: url('/assets/emerald.jpg');
    }

    &--ruby {
      background-image: url('/assets/ruby.jpg');
    }

    &--diamond {
      background-image: url('/assets/diamond.jpg');
    }

    &--black-diamond {
      background-image: url('/assets/black-diamond.png');
    }
  }

  &__head {
    @screen lg {
      margin-bottom: 180px;
    }
  }
}

.accordion {
  &__main {
    margin-bottom: 4px;
    transition: all .3s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, .07);
    }
  }

  &__head {
    padding-bottom: 15px;
    padding-top: 24px;
    padding-left: 27px;
    padding-right: 27px;
  }

  &__title {
    margin-bottom: 6px;
  }

  &__discount {
    margin-bottom: 6px;
  }

  &__content {
    display: none;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 33px;

    &--expanded {
      display: block;
    }
  }

  &__icon {
    top: 32px;
    right: 27px;
    height: 15px;
    width: 15px;

    &:after {
      @apply bg-dark absolute;
      content: '';
      display: block;
      top: 7px;
      width: 15px;
      height: 1px;
    }

    &--expanded {
      &:before {
        @apply bg-dark absolute;
        content: '';
        display: block;
        left: 7px;
        width: 1px;
        height: 15px;
      }
    }
  }
}
</style>

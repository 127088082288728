<template>
  <div class="flex flex-wrap items-center justify-between border-b mb-4 pb-4">
    <div class="thumb-col">
      <router-link :to="localizedRoute({
        name: product.type_id + '-product',
        params: { parentSku: product.parentSku ? product.parentSku : product.sku, slug: product.slug, childSku: product.sku }
      })"
      >
        <img v-lazy="thumbnail">
      </router-link>
    </div>
    <div class="w-full lg:w-2/5">
      <div @click="closeWishlist">
        <router-link class="font-bold text-h2 mb-3 block" :to="localizedRoute({
          name: product.type_id + '-product',
          params: { parentSku: product.parentSku ? product.parentSku : product.sku, slug: product.slug, childSku: product.sku }
        })"
        >
          {{ product.name | htmlDecode }}
        </router-link>
        <div class="h6 cl-bg-secondary pt5">
          {{ product.sku }}
        </div>
      </div>
      <div v-html="product.description" />
      <div class="mt-3">
        <base-textarea
          type="text"
          :value="''"
        >
          {{ $t('Please, enter your comments...') }}
        </base-textarea>
      </div>
    </div>
    <div class="w-full lg:w-1/5 xl:w-1/3">
      <div>
        <div class="text-right text-h2 text-primary font-bold mt-4 lg:mt-0 mb-3">
          <span class="price-special" v-if="product.special_price">{{ product.priceInclTax | price }}</span>&nbsp;
          <span class="price-original" v-if="product.special_price">{{ product.originalPriceInclTax | price }}</span>
          <span v-if="!product.special_price">
            {{ product.priceInclTax | price }}
          </span>
        </div>
        <div class="flex items-center justify-end lg:flex-col xl:flex-row add-to-cart-cta">
          <base-input-number
            :name="$t('Quantity')"
            :value="product.qty"
            :min="1"
            v-model="product.qty"
            v-if="product.disableQty === false || typeof product.disableQty === 'undefined'"
          />
          <button-full
            @click.native="addToCart(product)"
            :class="'w-full xl:w-auto'"
            :color="'primary'"
          >
            {{ $t('Add to cart') }}
          </button-full>
        </div>
        <div class="text-right">
          <div class="mt-3 inline-block text-red text-right cursor-pointer" @click="removeFromWishlist(product)">
            {{ $t('Remove') }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="11 === 2">
      <div @click="closeWishlist">
        <router-link :to="localizedRoute({
          name: product.type_id + '-product',
          params: { parentSku: product.parentSku ? product.parentSku : product.sku, slug: product.slug, childSku: product.sku }
        })"
        >
          <img v-lazy="thumbnail">
        </router-link>
      </div>
      <div class="col-xs between-xs flex pl40 py15">
        <div @click="closeWishlist">
          <router-link :to="localizedRoute({
            name: product.type_id + '-product',
            params: { parentSku: product.parentSku ? product.parentSku : product.sku, slug: product.slug, childSku: product.sku }
          })"
          >
            {{ product.name | htmlDecode }}
          </router-link>
          <div class="h6 cl-bg-secondary pt5">
            {{ product.sku }}
          </div>
        </div>
      </div>
      <div class="col-xs flex py15 align-right">
        <div>
          <span class="price-special" v-if="product.special_price">{{ product.priceInclTax | price }}</span>&nbsp;
          <span class="price-original" v-if="product.special_price">{{ product.originalPriceInclTax | price }}</span>
          <span v-if="!product.special_price">
            {{ product.priceInclTax | price }}
          </span>
        </div>
        <div>
          <div class="mt5">
            <span @click="removeFromWishlist(product)"><remove-button class="cl-accent" /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '@vue-storefront/core/compatibility/components/blocks/Wishlist/Product'
import RemoveButton from './RemoveButton'
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseInputNumber from 'theme/components/core/blocks/Form/BaseInputNumber'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
// @blur="updateQuantity"
// @input="updateQuantity"
export default {
  components: {
    RemoveButton,
    ButtonFull,
    BaseInputNumber,
    BaseTextarea
  },
  mixins: [Product],
  methods: {
    addToCart (productToCart) {
      this.$store.dispatch('cart/cartAddItem', { productToAdd: productToCart })
    },
    updateQuantity () {
    }
  }
}
</script>

<style lang="scss" scoped>
  .thumb-col {
    width: 100%;
    text-align: center;

    @screen lg {
      width: 100px;
    }

    @screen xl {
      width: 150px;
    }
  }
</style>

<style lang="scss">
  .add-to-cart-cta {
    .quantity-select-slim {
      max-width: 100%;

      @screen xl {
        max-width: 120px;
      }

      .quantity-select-action {
        height: 53px;
      }
    }
  }
</style>

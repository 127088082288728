<template>
  <div class="px-8.5 pt-5 pb-20 bg-white mb-5">
    <h2 class="mb-5 text-black font-normal tracking-tiny text-mid leading-h4 font-serif italic capitalize py-6">
      {{tickets.title}}
    </h2>
    <span class="tracking-mid block mb-5 uppercase text-h5">{{ $t('Tickets') }}</span>
    <ul class="ticket">
      <li v-for="item in tickets.items">
        <a href="#" class="ticket__item w-full block requirements-link bg-white border-solid border border-grey-6 text-base font-bold relative">
          <span class="block tracking-sm uppercase text-base font-black leading-none">{{item.quantity}}</span>
          <div class="flex">
            <span class="leading-base tracking-sm text-h5 font-normal pr-4 border-r border-solid border-grey-18">{{item.key}}</span>
            <span class="leading-base tracking-sm text-h5 font-normal pr-4 pl-4 border-r border-solid border-grey-18">{{item.name}}</span>
            <span class="leading-base tracking-sm text-h5 font-normal pl-4">{{item.email}}</span>
          </div>
          <i class="ss-gizmo ss-print absolute text-2h6 text-brown-2 ticket__icon"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AccountClassTickets',
  props: {
    tickets: {
      type: Object,
      required: false,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
  $grey-38: #c2c2c2;
  .ticket {
    &__item {
      margin-bottom: -1px;
      padding: 17px 60px 17px 22px;
      transition: background-color .5s ease;
      &:hover {
        background-color: rgba($grey-38, .3);
      }
    }
    &__icon {
      right: 23px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>

<template>
  <div class="order">
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-h5 md:italic md:font-serif md:tracking-tiny mb-5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      <span>{{ $t('Order') }}</span>
      <span>{{ `#${order.increment_id}` }}</span>
<!--      <span class="capitalize"> - {{ order.status }}</span>-->
      <small class="lg:float-right block lg:inline">{{ order.updated_at | moment("MMMM DD, YYYY") }}</small>
    </h2>
    <div class="bg-white lg:py-xl lg:px-8.5 p-md">
      <div class="lg:flex lg:justify-end mb-10">
        <button-full class="w-full lg:w-auto reorder-button lg:mr-sm mb-2 lg:mb-0" @click.native="remakeOrder(skipGrouped(order.items))">
          <span class="lg:text-sm text-h5">{{ $t('Reorder') }}</span>
          <i class="ss-gizmo ss-refresh inline-block align-middle ml-1 text-"></i>
        </button-full>
        <button-full class="w-full lg:w-auto print-button" @click.native="triggerPrint">
          <span class="lg:text-sm text-h5">{{ $t('Print order') }}</span>
          <i class="ss-gizmo ss-print inline-block align-middle ml-1"></i>
        </button-full>
      </div>
      <div class="lg:flex mb-5 lg:mb-10">
        <div class="mb-5 lg:mb-0 w-full lg:w-1/2">
          <div class="mb-sm text-base font-bold leading-normal tracking-tiny">
            {{ $t('Billing Address') }}
          </div>
          <div class="text-dark text-base tracking-xs leading-h5">
            <p>{{ billingAddress.firstname }}</p>
            <p>{{ billingAddress.lastname }}</p>
            <p>{{ billingAddress.street[0] }}</p>
            <p v-if="billingAddress.street[1]">
              {{ billingAddress.street[1] }}
            </p>
            <p>{{ `${billingAddress.city}, ${billingAddress.region}, ${billingAddress.postcode}` }}</p>
            <p>{{ billingAddress.country }}</p>
            <p v-if="billingAddress.telephone">
              {{ `T: ${billingAddress.telephone}` }}
            </p>
          </div>
        </div>
        <div class="w-full lg:w-1/2" v-if="!order.is_virtual">
          <div class="mb-sm text-base font-bold leading-normal tracking-tiny">
            {{ $t('Shipping Address') }}
          </div>
          <div class="text-dark text-base tracking-xs leading-h5">
            <p>{{ shippingAddress.firstname }}</p>
            <p>{{ shippingAddress.lastname }}</p>
            <p>{{ shippingAddress.street[0] }}</p>
            <p v-if="shippingAddress.street[1]">
              {{ shippingAddress.street[1] }}
            </p>
            <p>{{ `${shippingAddress.city}, ${shippingAddress.region}, ${shippingAddress.postcode}` }}</p>
            <p>{{ billingAddress.country }}</p>
            <p v-if="shippingAddress.telephone">
              {{ `T: ${shippingAddress.telephone}` }}
            </p>
          </div>
        </div>
      </div>
      <div class="lg:flex">
        <div class="mb-5 lg:mb-0 w-full lg:w-1/2">
          <div class="mb-sm text-base font-bold leading-normal tracking-tiny">
            {{ $t('Payment Method') }}
          </div>
          <div class="text-dark text-base tracking-xs leading-h5">
            <p class="pb-2">
              {{ paymentMethod }}
            </p>
            <div v-if="paymentInformation.length && this.order.payment.method === 'stored_card'">
              <div
                v-for="item in paymentInformation"
                :key="item.entity_id"
                class="p-sm mb-sm bg-grey-27"
              >
                <p
                  v-if="getCardBrandName(item.brand_id)"
                  :style="{ color: getBrandColor(item.brand_id) }"
                  class="font-semibold"
                >
                  {{ getCardBrandName(item.brand_id) }}
                </p>
                <p class="italic">
                  {{ `${item.cc_type}` }}<br>
                  {{ `${item.cc_number}, ${item.cc_expiry_date}` }}
                </p>
                <p v-if="item.po_number">
                  {{ $t(`PO Number ${item.po_number}`) }}
                </p>
              </div>
            </div>
            <div v-if="paymentInformation.length && this.order.payment.method === 'terms'" class="text-dark text-base tracking-xs leading-h5">
              <div class="italic pb-2">{{ (((this.order || {}).payment || {}).additional_information || [])[2] || '' }}</div>
              <div v-if="((this.order || {}).payment || {}).po_number" class="italic">PO #: {{ ((this.order || {}).payment || {}).po_number }}</div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2" v-if="!order.is_virtual">
          <div class="mb-sm text-base font-bold leading-normal tracking-tiny">
            {{ $t('Shipping Method') }}
          </div>
          <div class="text-dark text-base tracking-xs leading-h5">
            <p>{{ order.shipping_description }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none lg:leading-h5 md:italic md:font-serif md:tracking-tiny lg:my-10 uppercase text-center md:text-left md:normal-case font-bold tracking-lg my-5 py-5 border-b border-grey-38 lg:border-0">
      <span>{{ $t('Items ordered') }}</span>
    </h2>
    <div class="bg-white lg:py-xl lg:px-8.5 p-md">
      <table class="w-full my-10">
        <thead>
          <tr>
            <th class="text-left font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden lg:table-cell">
              {{ $t('Product name') }}
            </th>
            <th class="text-left font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden lg:table-cell">
              {{ $t('Sku') }}
            </th>
            <th class="text-right font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden lg:table-cell">
              {{ $t('Price') }}
            </th>
            <th class="text-right font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden lg:table-cell">
              {{ $t('QTY') }}
            </th>
            <th class="text-right font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden lg:table-cell">
              {{ $t('Subtotal') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in groupedProducts">
            <tr :key="item.id" v-if="item.total">
              <td class="text-left text-above-md lg:text-base font-bold leading-none uppercase tracking-mid py-sm block lg:table-cell">
                {{ item.name }}
              </td>
            </tr>
            <tr
              v-for="product in item.products"
              v-if="item.total"
              :key="product.sku"
              class="block lg:table-row py-sm lg:py-0"
            >
              <td class="lg:pb-sm text-above-md lg:text-base leading-sm tracking-xs text-dark block lg:table-cell order__cell">
                {{ product.name }}
              </td>
              <td class="lg:pb-sm text-above-md lg:text-base leading-sm tracking-xs text-dark block lg:table-cell order__cell">
                {{ product.sku }}
              </td>
              <td class="lg:pb-sm text-above-md lg:text-base text-heading-page leading-h5 font-serif tracking-xs text-dark italic lg:text-right block lg:table-cell order__cell" :data-content="$t('Price')">
                <span>
                  <span v-if="product.extension_attributes.points_used">{{ (product.extension_attributes.points_used / (product.extension_attributes.points_used / product.price)) }} Pts + </span>
                  <span>{{ product.price | price }}</span>
                </span>
              </td>
              <td class="lg:pb-sm text-above-md lg:text-base leading-sm tracking-xs text-dark lg:text-right block lg:table-cell order__cell" :data-content="$t('Qty')">
                {{ $t(`Ordered: ${product.qty_ordered}`) }}
              </td>
              <td class="lg:pb-sm text-above-md lg:text-base text-heading-page leading-h5 font-serif tracking-xs text-dark italic lg:text-right block lg:table-cell order__cell" :data-content="$t('Total Price')">
                <span>
                  {{ product.row_total_incl_tax | price }}
                </span>
              </td>
            </tr>
            <tr :key="item.id + '-subtotal'" v-if="item.total">
              <td class="text-left text-above-md lg:text-base font-bold leading-none tracking-mid lg:text-right pb-md lg:pb-sm table-cell" colspan="4">
                {{ $t(`${item.name} Subtotal`) }}
              </td>
              <td class="text-left text-above-md lg:text-base font-bold leading-none tracking-mid text-right pb-md lg:pb-sm table-cell" colspan="1">
                {{ getBrandSubtotal(index) | price }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="text-right">
              {{ $t('Subtotal') }}
            </td>
            <td colspan="1" class="text-heading-page leading-h5 font-serif tracking-xs text-dark underline italic text-right">
              {{ getOrderSubtotal | price }}
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right">
              {{ $t('Shipping & Handling') }}
            </td>
            <td colspan="1" class="text-heading-page leading-h5 font-serif tracking-xs text-dark underline italic text-right">
              {{ order.shipping_amount | price }}
            </td>
          </tr>
          <tr>
            <td colspan="4" class="font-bold leading-none text-heading-page leading-normal text-black pb-sm pt-md text-right">
              {{ $t('Grand Total') }}
            </td>
            <td colspan="1" class="text-heading-page leading-h5 font-serif tracking-xs text-dark underline italic text-right">
              {{ order.grand_total | price }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import MyOrder from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyOrder'
import ButtonFull from 'theme/components/theme/ButtonFull'
import moment from 'theme/filters/moment'
import { mapGetters } from 'vuex'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'

export default {
  mixins: [MyOrder],
  components: {
    ButtonFull
  },
  filters: {
    moment
  },
  computed: {
    ...mapGetters({
      getBrands: 'brands/getBrands',
      accounts: 'loyalty/getCustomerAccounts'
    }),
    paymentMethod () {
      return this.order.payment.additional_information[1]
    },
    paymentInformation () {
      let paymentMethod = JSON.parse(this.order.payment.additional_information[0])
      let paymentInformation = Object.values(paymentMethod)
      return paymentInformation || []
    },
    brandList () {
      let brands = []

      this.getBrands.forEach(brand => {
        brands.push({
          id: brand.id,
          name: brand.name,
          products: []
        })
      })

      return brands
    },
    groupedProducts () {
      let groupedProducts = this.brandList || []
      let products = this.order.items

      groupedProducts.forEach(brand => {
        let productByBrand = products.filter(product => product.extension_attributes.brand_id === brand.id)

        if (!productByBrand) return

        brand.products = productByBrand

        brand.total = brand.products.reduce((prev, cur) => {
          return prev + cur.row_total_incl_tax
        }, 0)
      })

      return groupedProducts
    },
    getOrderSubtotal () {
      let subtotal = 0
      let products = this.order.items

      products.forEach(product => {
        if (product.extension_attributes.points_used) return
        subtotal += product.row_total_incl_tax
      })

      return subtotal
    }
  },
  methods: {
    triggerPrint () {
      if (window) {
        window.print()
      }
    },
    round (val, increment) {
      val = parseInt(val, 10)
      if (val < increment) {
        return increment
      }
      return Math.round(val / increment) * increment
    },
    async remakeOrder (products) {
      this.$bus.$emit('notification-progress-start', this.$t('Please wait ...'))
      const productsToAdd = []
      const loyaltyProductsToAdd = []

      for (const item of products) {
        let product = await this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false })

        let loyaltyQuantity = ((item.extension_attributes || {}).reward_data || {}).qty || 0

        product.qty = this.round((item.qty_ordered - loyaltyQuantity) || product.qty, getProductQtyIncrement(product))

        productsToAdd.push(product)

        if (loyaltyQuantity) {
          loyaltyProductsToAdd.push({
            ...product,
            qty: loyaltyQuantity
          })
        }
      }

      await this.$store.dispatch('cart/addItems', { productsToAdd })

      for (const item in loyaltyProductsToAdd) {
        await this.$store.dispatch('cart/upsertLoyaltyItem', {
          product: item,
          qty: item.qty,
          upsert: false // we want to update on old qty
        })
      }

      this.$bus.$emit('notification-progress-stop', {})
      this.$router.push(this.localizedRoute('/cart'))
    },
    getBrandSubtotal (index) {
      let subtotal = 0
      let products = this.groupedProducts[index]['products']

      products.forEach(product => {
        if (product.extension_attributes.points_used) return
        subtotal += product.row_total_incl_tax
      })

      return subtotal
    },
    getCardBrandName (id) {
      let brand = this.getBrands.find(brand => brand.id == id)

      return brand ? brand.name : ''
    },
    getBrandColor (id) {
      let account = this.accounts.find(account => account.bank_id == id)

      return account ? `#${account.bank.hex_color}` : '#000000'
    },
    skipGrouped (items) {
      return items.filter((item) => {
        return !item.parent_item_id && !item.extension_attributes.points_used
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .order {
    &__cell[data-content] {
      &:after {
        @apply inline-block float-left mr-xs;
        content: attr(data-content) ":";
        @screen lg {
          @apply hidden;
        }
      }
    }
  }

  .print-button,
  .reorder-button {
    @apply py-3;
    @screen lg {
      @apply px-5 py-xs;
    }
  }
</style>

<template>
  <brand-tabs :tabs="getTabs" :current-tab="currentTab">
    <stored-cards-content :card="currentStoredCard" />
  </brand-tabs>
</template>

<script>
import {mapGetters} from 'vuex'
import BrandTabs from 'theme/components/theme/BrandTabs'
import StoredCardsContent from 'theme/components/theme/blocks/Account/StoredCardsContent'
import StoredCards from 'theme/mixins/blocks/StoredCards'

export default {
  name: 'StoredCardTabs',
  mixins: [
    StoredCards
  ],
  components: {
    BrandTabs,
    StoredCardsContent
  },
  data() {
    return {
      currentTab: this.getCurrentTab ? this.getCurrentTab : '',
      currentStoredCard: this.getCurrentStoredCard ? this.getCurrentStoredCard : {}
    }
  },
  beforeMount () {
    this.$bus.$on('brands-tab-content', this.getTabContent)
  },
  beforeDestroy () {
    this.$bus.$off('brands-tab-content', this.getTabContent)
  },
  computed: {
    ...mapGetters({
      getBrands: 'brands/getBrands'
    }),
    getTabs () {
      let tabs = {}
      let storedCards = this.getMappedStoredCards

      for (let key in storedCards) {
        if (!storedCards.hasOwnProperty(key)) continue

        tabs[key] = {
          label: storedCards[key]['name'],
          value: storedCards[key]['name'],
          id: key
        }
      }

      return tabs
    },
    getFirstKey () {
      return Object.keys(this.getMappedStoredCards)[0]
    },
    getCurrentTab () {
      return this.getTabs[this.getFirstKey] ? this.getTabs[this.getFirstKey]['label'] : ''
    },
    getCurrentStoredCard () {
      return this.getMappedStoredCards[this.getFirstKey]
    },
    getChildBrands () {
      return this.getBrands.filter(brand => brand['parent_id'])
    },
    getMappedStoredCards () {
      let storedCards = this.getStoredCardsList

      if (!this.getChildBrands.length) return storedCards

      this.getChildBrands.forEach(brand => {
        let parentBrand = Object.keys(storedCards)
          .find(key => storedCards[key]['brandId'] === brand['parent_id'])

        if (storedCards[parentBrand] && storedCards[brand.code] && storedCards[brand.code].storedCards.length) {
          storedCards[parentBrand].storedCards.push(...storedCards[brand.code].storedCards)
        }
      })

      return storedCards
    }
  },
  methods: {
    getTabContent(componentName) {
      if (!componentName) componentName = this.getCurrentTab

      this.getContent(componentName)
      this.currentTab = componentName
    },
    getContent(name) {
      let storedCards = Object.values(this.getMappedStoredCards)

      storedCards.map(storedCard => {
        if (storedCard.name === name) this.currentStoredCard = storedCard
      })
    }
  }
}
</script>

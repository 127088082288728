<template>
  <div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Events & Classes - In-Salon Classes') }}
    </h2>
    <div v-if="request !== null" v-for="item in request">
      <account-class :eventItem="item" :downloadable="true" />
    </div>
    <div v-else class="px-8.5 pt-xl pb-xl bg-white mb-5">
      You have no tickets for upcoming events or classes. Please visit the Education calendar to browse upcoming events and classes.
    </div>
  </div>
</template>

<script>
import AccountClass from './AccountClass'
export default {
  name: 'InSalonClasses',
  components: {
    AccountClass
  },
  data () {
    return {
      request: [
        {
          title: 'R+Co Introduction to Styling',
          description: 'R+Co\'s Introduction to Styling class will showcase techniques from the varying aesthetics of the brand.',
          image: '/assets/styling_image.jpeg',
          date: 'Monday, August 19, 2019',
          dates: [
            {
              count: '1st',
              value: 'Thursday, April 20 2017'
            },
            {
              count: '2nd',
              value: 'Tuesday, May 30 2017'
            },
            {
              count: '3rd',
              value: 'Wednesday, June 14 2017'
            },
          ],
          city: 'Miami, Florida 33137',
          name: 'LBP Technology',
          address: '4040 NE 2nd Avenue Suite 414',
          status: 'pending'
        }
      ]
    }
  }
}
</script>

<template>
  <component :is="simpleAsArrow ? 'button' : 'button-full'" @click.native="simpleAsArrow ? null : $router.go(-1)" @click="!simpleAsArrow ? null : $router.go(-1)" type="button" :class="{ 'back-button relative': !simpleAsArrow }">
    <i class="ss-gizmo back-icon ss-left absolute text-above-md md:text-h5 top-50% icon-md md:icon-xl" :class="{ 'left-20px': !simpleAsArrow }"></i>
    <span class="hidden md:inline-block">{{ $t('Back') }}</span>
  </component>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
export default {
  name: 'BackButton',
  components: {
    ButtonFull
  },
  props: {
    simpleAsArrow: {
      type: Boolean,
      requred: false,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.back-button {
  @apply pr-5 pl-15 text-left;
}
.back-icon {
  transform: translate(0, -50%);
}
</style>

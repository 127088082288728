<template>
  <div>
    <h1>My Reseller Certificates</h1>
    <form ref="form">
      <base-select
        name="location"
        class="location mb-sm"
        v-model="formData.netsuite_id"
        :options="getShippingLocations"
        :selected="formData.netsuite_id"
        :validations="$v.formData.netsuite_id
                ? [
                    {
                      condition:
                        $v.formData.netsuite_id.$error &&
                        !$v.formData.netsuite_id.required,
                      text: validateMessage('required', { attr: 'Location' }),
                    }
                  ]
                : []"
      >
        {{ $t('Location:') }}
      </base-select>
      <base-input
        tabindex="17"
        :input-class="'text-black'"
        placeholder="Reseller Certificate"
        name="reseller_certificate"
        v-model="formData.reseller_certificate"
        @change="setFile"
        type="file"
        accept=".pdf, .png, .jpg, .jpeg, .tiff"
        :validations="$v.formData.reseller_certificate
                ? [
                    {
                      condition:
                        $v.formData.reseller_certificate.$error &&
                        !$v.formData.reseller_certificate.required,
                      text: validateMessage('required', { attr: 'Reseller Certificate' }),
                    }
                  ]
                : []"
      >
        {{ $t('Upload Certificate:') }}
      </base-input>

      <button @submit.prevent="submit" type="submit">Upload</button>
    </form>
    <div>
      <h2></h2>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {mapGetters, mapState} from 'vuex'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import mapValues from 'lodash-es/mapValues'
import groupBy from 'lodash-es/groupBy'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import {httpClient} from 'theme/helpers/httpClient'
import validateMessage from 'theme/helpers/valdiateMessage'
import i18n from '@vue-storefront/i18n'

export default {
  name: 'MyResellerCert',
  components: {
    BaseInput,
    BaseSelect
  },
  mixins: [validateMessage],
  data () {
    return {
      certificates: [],
      formData: {
        netsuite_id: '',
        reseller_certificate: null
      }
    }
  },
  mounted () {
    this.certificates = this.getCertificates()
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    ...mapGetters({
      location: 'storedCards/getLocation'
    }),
    getShippingLocations () {
      let userShippingLocations = this.currentUser ? this.currentUser.extension_attributes.locations_data : []

      // group by location id
      userShippingLocations = mapValues(groupBy(userShippingLocations, 'address_id'), list => {
        let loc = list[0]
        return {
          addressId: loc.address_id,
          brandId: loc.brand_id,
          locationId: loc.location_id,
          value: `${loc.location_id}`,
          label: loc.nick_name,
          enabled: loc.status
        }
      })

      return Object.values(userShippingLocations).filter(location => location.enabled === true)
    }
  },
  methods: {
    setFile (event) {
      this.formData.reseller_certificate = event
    },
    async getCertificates () {
      let results = []

      for (let cert of this.getShippingLocations) {
        try {
          results.push(await httpClient.get('https://data-bridge.luxbp.com/v1/reseller/' + cert.locationId))
        } catch (err) {
          console.error(err)
        }
      }

      return results
    },
    submit () {
      let bodyFormData = new FormData()
      let formData = Object.assign({}, this.formData)

      for (let key in formData) {
        bodyFormData.append(key, formData[key])
      }

      httpClient.post('https://data-bridge.luxbp.com/v1/reseller-upload', bodyFormData).then((response) => {
        // handle success
        if (response.data.status === true) {
          this.$refs.form.reset()
          this.$store.dispatch('notification/spawnNotification', {
            type: 'success',
            message: i18n.t('You have been successfully uploaded your reseller certificate!'),
            action1: { label: i18n.t('OK') }
          })
          this.certificates = this.getCertificates()
        }
      })
    }
  },
  validations () {
    return {
      reseller_certificate: {
        required
      },
      netsuite_id: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>

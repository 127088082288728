<template>
  <div class="p-5 lg:px-8.5 lg:pt-5 lg:pb-10 bg-white mb-5">
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{eventItem.event_title}}
    </h2>
    <div class="lg:flex justify-between">
      <div class="w-full lg:w-1/3 flex lg:block">
        <div class="lg:w-full w-1/2">
          <img :src="eventThumbnail" :alt="eventItem.event_title">
        </div>
        <div class="lg:w-full w-1/2 pl-3 lg:pl-0">
          <div class="pl-4 lg:pl-1 lg:mt-10" v-if="eventItem.id">
            <div>
              <router-link :to="localizedRoute({name: 'classes-tickets', params: {ticketId: eventItem.id}})"
                class="class-link uppercase block text-sm relative tracking-lg text-dark-4 leading-none relative inline-block">
                {{ $t('View ticket') }}
              </router-link>
            </div>
            <div>
              <router-link :to="localizedRoute({name: 'classes-detail', params: {classesId: eventItem.id}})"
                class="class-link uppercase block text-sm relative tracking-lg text-dark-4 leading-none relative inline-block">
                {{ $t('View class materials') }}
              </router-link>
            </div>
          </div>
          <div v-if="downloadable" class="status mt-lg relative font-bold pl-8.5">
            <span class="text-sm lg:text-h5 uppercase tracking-sm leading-none lg:leading-normal block lg:inline-block">Event status: {{eventItem.status}}</span>
            <i class="status__icon absolute border-2 border-solid border-brown-2"></i>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-7/12">
        <div v-if="eventItem.event_description" class="lg:pb-7 mb-3 pb-3 mt-3 lg:mt-0 lg:mb-10 font-sans text-h5 lg:text-above-md leading-normal lg:leading-sm border-b border-grey-9 border-solid" v-html="eventItem.event_description" />
        <div class="flex">
          <div class="w-1/2 pr-3">
            <span class="text-black font-normal black tracking-tiny lg:text-mid leading-h4 font-serif italic text-h3">{{
              $t('When') }}</span>
              <p class="font-sans lg:text-base text-h5 leading-normal lg:leading-sm">{{ eventItem.event_start_date | moment("dddd, MMM DD, YYYY [at] h:mm a") }}</p>
              <p class="font-sans lg:text-base text-h5 leading-normal lg:leading-sm">{{ $t('to') }}</p>
              <p class="font-sans lg:text-base text-h5 leading-normal lg:leading-sm">{{ eventItem.event_end_date | moment("dddd, MMM DD, YYYY [at] h:mm a") }}</p>
          </div>
          <div class="w-1/2">
            <span class="text-black font-normal black tracking-tiny lg:text-mid leading-h4 font-serif italic text-h3">{{
              $t('Where') }}</span>
            <span class="block font-sans lg:text-base text-h5 leading-normal lg:leading-sm">{{eventItem.event_address}}</span>
          </div>
        </div>
        <div v-if="downloadable" class="border-t border-solid border-grey-6 mt-9 pt-8 lg:pt-10">
          <span class="uppercase text-h5 tracking-mid block lg:mb-5">Downloadable materials</span>
          <a href="#" target="_blank" class="w-full block requirements-link bg-white border-solid border border-grey-6 text-base font-bold relative">
            <span>Model Requirements</span>
            <i class="ss-gizmo ss-download requirements-link__icon absolute text-2h6 text-brown-2"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'theme/filters/moment'
export default {
  name: 'AccountClass',
  props: {
    eventItem: {
      type: Object,
      required: false,
      default: {}
    },
    downloadable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  filters: {
    moment
  },
  computed: {
    eventThumbnail () {
      return this.getThumbnail(this.eventItem.event_image, 250, 250)
    }
  }
}

</script>
<style lang="scss" scoped>
$grey-38: #c2c2c2;

.class-link {
  transition: color .25s ease;

  &:after {
    @apply absolute bg-grey-37 w-full;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
  }

  &:hover {
    @apply text-brown-2;
  }
}

.requirements-link {
  padding: 10px 60px 10px 10px;
  transition: background-color .5s ease;

  @screen xl {
    padding: 17px 60px 17px 23px;
  }

  &__icon {
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: rgba($grey-38, .3);
  }
}

.status {
  &__icon {
    border-radius: 50%;
    height: 27px;
    margin-top: -15px;
    top: 50%;
    left: 0;
    width: 27px;

    &:before {
      @apply text-brown-2 text-center absolute text-mid leading-h4;
      content: '\2026';
      height: 23px;
      top: -8px;
      width: 23px;
    }
  }
}

</style>

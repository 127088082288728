<template>
  <div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Events & Classes') }}
    </h2>
    <account-class-details :details="details" />
  </div>
</template>

<script>
import AccountClassDetails from './AccountClassDetails'
export default {
  name: 'StaticClassDetails',
  components: {
    AccountClassDetails
  },
  data () {
    return {
      details: {
        title: 'R+Co Workshop San Francisco - Demonstration Session',
        description: 'This one-day class will deliver a deeper understanding of the specific cutting and styling needs of curly textures. Students will have the opportunity to learn through demonstrations as well as hands-on application of cutting and styling techniques on live models with curl textures ranging from tight to coily curls.',
        image: '/assets/aixa.jpg',
        date: 'Monday, June 24, 2019',
        time: '9:30am-4:30pm',
        city: 'Oakland, CA 94618',
        name: 'Pony Studios',
        address: '6012 College Ave',
        download_title: 'SFO Workshop',
        link_tickets: '/static/customer/classes/tickets'
      }
    }
  }
}
</script>

<template>
  <div class="rewards">
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Rewards') }}
    </h2>
    <account-rewards />
    <rewards-level />
    <account-next-level v-if="nextTierExists" />
<!--    <earn-more />-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AccountRewards from 'theme/components/theme/blocks/Account/AccountRewards'
import AccountNextLevel from 'theme/components/theme/blocks/Account/AccountNextLevel'
import RewardsLevel from 'theme/components/theme/blocks/Account/RewardsLevel'
// import EarnMore from 'theme/components/theme/blocks/Account/EarnMore'

export default {
  name: 'Rewards',
  components: {
    AccountRewards,
    AccountNextLevel,
    RewardsLevel,
    // EarnMore
  },
  computed: {
    ...mapGetters({
      dashboardInfo: 'loyalty/getDashboard'
    }),
    nextTierExists () {
      return this.dashboardInfo ? !!this.dashboardInfo.next_tier : false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

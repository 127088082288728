<template>
  <div class="content">
    <div class="tabs-menu hidden lg:block">
      <div
        v-for="(tab, key) in tabs"
        :key="key"
        class="tabs-menu__item inline-block mr-px"
      >
        <div @click="getComponent(tab.value)">
          <span
            class="tabs-menu__link hover:bg-black hover:text-white relative cursor-pointer uppercase px-xl bg-grey-16 block text-h5 select-none tracking-lg"
            :class="[
              tab.value === currentTab ? `tabs-menu__link-${tab.id}--active` : '',
              `tabs-menu__link-${tab.id}`,
              tab.value === currentTab ? `tabs-menu__link--active` : ''
            ]"
          >
            {{ tab.label }}
          </span>
        </div>
      </div>
    </div>
    <base-select
      name="tabs"
      :options="getOptionList"
      class="tabs-menu block lg:hidden mb-5"
      input-class="uppercase"
      :placeholder="currentTab"
      :selected="currentTab"
      v-model="selected"
      autocomplete="card-type"
      :changed="getComponent(selected)"
    />
    <slot />
  </div>
</template>

<script>
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'

export default {
  name: 'BrandTabs',
  components: {
    BaseSelect
  },
  data () {
    return {
      selected: ''
    }
  },
  props: {
    tabs: {
      type: Object,
      required: true,
      default: () => {}
    },
    currentTab: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    getOptionList () {
      return Object.values(this.tabs)
    }
  },
  methods: {
    getComponent: function (componentName) {
      this.$bus.$emit('brands-tab-content', componentName)
    }
  },
  watch: {
    currentTab (newVal) {
      this.selected = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
  $black: #000;
  $green: #00e1ba;
  $red: #f84876;
  $yellow: #f3f281;

  .tabs-menu {
    &__item {
      height: 56px;
    }
    &__link {
      line-height: 56px;
      &:after {
        @apply absolute leading-none border-6 border-transparent border-solid;
        bottom: -12px;
        content: '';
        left: calc(50% - 6px);
        transition: none;
      }
      &--active {
        @apply bg-black text-white;
        &:after {
          border-top-color: $black;
        }
      }

      &-rco {
        &--active {
          @apply bg-green text-black;
          &:after {
            border-top-color: $green;
          }
        }
        &:hover {
          @apply bg-green text-black;
        }
      }
      &-sc {
        &--active {
          @apply bg-red text-white;
          &:after {
            border-top-color: $red;
          }
        }
        &:hover {
          @apply bg-red text-white;
        }
      }
      &-v76 {
        &--active {
          @apply bg-yellow text-black;
          &:after {
            border-top-color: $yellow;
          }
        }
        &:hover {
          @apply bg-yellow text-black;
        }
      }
    }
  }
</style>

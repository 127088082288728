<template>
  <div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Web Profit Share') }}
    </h2>
    <div class="lg:px-8.5 lg:pt-xl lg:pb-xl bg-white mb-5 p-md">
      <div class="lg:flex justify-between mb-md lg:mb-0">
        <h2 class="mb-10 text-black font-normal tracking-tiny text-mid leading-h4 font-serif italic capitalize hidden lg:inline">
          {{ $t('Detailed earnings') }}
        </h2>
        <base-select
          class="profit-select"
          name="detailed"
          :options="detailedYear"
          v-model="selected"
          :changed="getDetailedEarnings(selected)"
        />
      </div>
      <div v-if="selectedDetails !== null">
        <table width="100%">
          <thead>
            <tr>
              <th class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Profit') }}</th>
              <th class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Total Referred Profit') }}</th>
              <th class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Profit Share Rate') }}</th>
              <th class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Earned Profit') }}</th>
            </tr>
          </thead>
          <tbody v-for="item in selectedDetails.payload">
            <tr>
              <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">
                {{item.quarter}}
                <span class="block text-h4 text-grey-2">
                  ({{item.term}})
                </span>
              </td>
              <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">{{item.total}}</td>
              <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">
                {{item.rate}}
                <span class="block text-h4 text-grey-2">({{item.type}})</span>
              </td>
              <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">{{item.earned}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3" class="text-right pr-10 text-h5 text-dark tracking-mid py-xl uppercase text-h4 lg:text-base lg:leading-base leading-loose">Total Earned Profit this Year (As Of Jul 12):</td>
              <td class="text-lg text-h4 lg:text-base">{{selectedDetails.totals}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-else>
        <span>There is no Web Profit Share information for {{selected}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'

export default {
  name: 'WebprofitDetail',
  components: {
    BaseSelect
  },
  data () {
    return {
      selected: '2019',
      selectedDetails: {},
      detailedYear: [
        { label: '2019', value: '2019' },
        { label: '2018', value: '2018' },
        { label: '2017', value: '2017' },
        { label: '2016', value: '2016' }
      ],
      detailsEarnings: [
        {
          year: ''
        },
        {
          year: '2018',
          totals: '$0.00',
          payload: [
            {
              quarter: '1st Quarter',
              term: 'January 01 - March 31',
              total: '$0.00',
              rate: '20%',
              type: 'Pearl',
              earned: '$0.00',
            },
            {
              quarter: '2nd Quarter',
              term: 'April 01 - June 30',
              total: '$0.00',
              rate: '20%',
              type: 'Pearl',
              earned: '$0.00',
            }
          ]
        },
        {
          year: '2017',
          totals: '$0.00',
          payload: [
            {
              quarter: '1st Quarter',
              term: 'January 01 - March 31',
              total: '$0.00',
              rate: '40%',
              type: 'Emerald',
              earned: '$0.00',
            },
            {
              quarter: '2nd Quarter',
              term: 'April 01 - June 30',
              total: '$0.00',
              rate: '20%',
              type: 'Pearl',
              earned: '$0.00',
            },
            {
              quarter: '3rd Quarter',
              term: 'July 01 - September 30',
              total: '$0.00',
              rate: '20%',
              type: 'Pearl',
              earned: '$0.00',
            },
            {
              quarter: '2th Quarter',
              term: 'October 01 - December 31',
              total: '$0.00',
              rate: '20%',
              type: 'Pearl',
              earned: '$0.00',
            }
          ]
        },
        {
          year: '2016',
          totals: '$0.00',
          payload: [
            {
              quarter: '2nd Quarter',
              term: 'April 01 - June 30',
              total: '$0.00',
              rate: '30%',
              type: 'Sapphire',
              earned: '$0.00',
            },
            {
              quarter: '3rd Quarter',
              term: 'July 01 - September 30',
              total: '$0.00',
              rate: '30%',
              type: 'Sapphire',
              earned: '$0.00',
            },
            {
              quarter: '2th Quarter',
              term: 'October 01 - December 31',
              total: '$0.00',
              rate: '30%',
              type: 'Sapphire',
              earned: '$0.00',
            }
          ]
        }
      ]
    }
  },
  methods: {
    getDetailedEarnings (value) {
      let detailsEarnings = this.detailsEarnings
      let selectedDetails = null
      detailsEarnings.map(item => {
        if (item.year === value) {
          selectedDetails = item
        }
      })
      this.selectedDetails = selectedDetails
      return this.selectedDetails !== null
    }
  }
}
</script>

<style lang="scss" scoped>
  .profit-select {
    &::v-deep {
      select {
        @apply border-grey-21 bg-grey-27;
        @screen lg {
          @apply bg-white;
          width: 216px;
        }
      }
      .icon {
        width: 8px;
      }
    }
  }
</style>

<template>
  <div class="lg:overflow-visible">
    <orders-pagination
      paginationClass="lg:-mb-lg mb-md"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @perPageChange="perPageChange"
    />
    <template v-if="false">
      <div class="text-h4 leading-base tracking-mid mb-md hidden lg:block">
        <span class="block">{{ $t('Need to make a return?') }}</span>
        <span class="block">{{ $t('Please complete the Return Authorization Form') }}</span>
      </div>
      <router-link :to="localizedRoute('/static/rma')" class="inline-block slow-color-change whitespace-no-wrap outline-none focus:outline-none rounded-0 bg-primary hover:bg-primary2 border-0 text-white px-5 lg:py-xs text-center uppercase tracking-sm w-full lg:w-auto py-3">
        <span class="text-h5 lg:text-sm">{{ $t('Return Authorization Form') }}</span>
      </router-link>
    </template>
    <table class="w-full my-10" :class="{ loadingOrders: isLoadingOrders }">
      <thead>
        <tr>
          <th class="text-left font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm">{{
            $t('ORDER #') }}</th>
          <th class="text-left font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden sm:table-cell">
            {{ $t('DATE') }}
          </th>
          <th class="text-left font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden lg:table-cell">
            {{ $t('SHIP TO') }}
          </th>
          <th class="text-right font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden md:table-cell">
            {{ $t('ORDER TOTAL') }}
          </th>
     <!--     <th class="text-right font-normal text-h5 leading-none uppercase tracking-mid border-b border-dark border-solid pb-sm hidden sm:table-cell">
            {{ $t('ORDER STATUS') }}
          </th>-->
        </tr>
      </thead>
      <tbody v-show="isHistoryEmpty">
        <tr>
          <td class="w-1/2 py-2"><p class="leading-base tracking-mid mb-md my-3">{{ $t('Looks like you haven\'t placed any orders.') }}</p></td>
        </tr>
      </tbody>
      <tbody>
        <tr :key="order.entity_id" v-for="order in orders">
          <td class="pt-sm">
            <router-link :to="localizedRoute({name: 'my-order', params: {orderId: order.entity_id}})" class="pt-sm text-base leading-sm tracking-xs text-dark underline hover:text-primary2 slow-color-change">
              {{ order.increment_id }}
            </router-link>
          </td>
          <td class="pt-sm hidden sm:table-cell">
            <router-link :to="localizedRoute({name: 'my-order', params: {orderId: order.entity_id}})" class="pt-sm text-base leading-sm tracking-xs text-dark underline hover:text-primary2 slow-color-change">
              {{ order.created_at | localeDate }}
            </router-link>
          </td>
          <td class="pt-sm hidden lg:table-cell">
            <router-link :to="localizedRoute({name: 'my-order', params: {orderId: order.entity_id}})" class="pt-sm text-base leading-sm tracking-xs text-dark underline hover:text-primary2 slow-color-change"  v-if="!order.is_virtual">
              {{ shipTo(order) }}
            </router-link>
            <span v-else class="pt-sm text-base leading-sm tracking-xs text-dark uppercase">
              {{ $t('This product is virtual') }}
            </span>
          </td>
          <td class="pt-sm text-right hidden md:table-cell">
            <router-link :to="localizedRoute({name: 'my-order', params: {orderId: order.entity_id}})" class="text-base leading-sm tracking-xs text-dark italic font-serif underline hover:text-primary2 slow-color-change">
              {{ order.grand_total | price }}
            </router-link>
          </td>
  <!--        <td class="pt-sm text-right hidden sm:table-cell">
            <router-link :to="localizedRoute({name: 'my-order', params: {orderId: order.entity_id}})" class="text-base leading-sm tracking-xs text-dark underline hover:text-primary2 slow-color-change">
              {{ order.status | capitalize }}
            </router-link>
          </td>-->
        </tr>
      </tbody>
    </table>
    <orders-pagination
      paginationClass="lg:-mb-lg mb-md"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
  </div>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import MyOrders from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyOrders'
import OrdersPagination from 'theme/components/core/blocks/MyAccount/OrdersPagination'
import debounce from 'lodash-es/debounce'
import {
  localeDate
} from 'theme/filters/locale-date'
export default {
  name: 'OrdersTable',
  mixins: [MyOrders],
  mounted () {
    this.$store.dispatch('user/getOrdersHistory', { refresh: true, useCache: true, pageSize: this.perPage, currentPage: this.currentPage })
  },
  data () {
    return {
      isLoadingOrders: false
    }
  },
  filters: {
    localeDate
  },
  components: {
    OrdersPagination
  },
  computed: {
    ...mapGetters({
      perPage: 'myaccount/getPerPage'
    }),
    currentPage: {
      get () {
        return this.$store.state.myaccount.current
      },
      set (value) {
        this.$store.dispatch('myaccount/updateCurrentPagePagination', value)
      }
    },
    orders() {
      return this.ordersHistory
    }
  },
  watch: {
    orders () {
      this.isLoadingOrders = false
    }
  },
  methods: {
    fetchOrderPage: debounce( function(nextPage = 1) {
      this.$store.dispatch('user/getOrdersHistory', { refresh: true, useCache: true, pageSize: this.perPage, currentPage: nextPage })
    }, 250),
    prevPage () {
      this.isLoadingOrders = true
      this.currentPage -= 1
      this.fetchOrderPage(this.currentPage)
    },
    nextPage () {
      this.isLoadingOrders = true
      this.currentPage += 1
      this.fetchOrderPage(this.currentPage)
    },
    perPageChange (val = 25) {
      this.$store.dispatch('myaccount/resetCurrentPagePagination')
      this.$store.dispatch('myaccount/updatePerPage', val)
      this.fetchOrderPage(this.currentPage)
    },
    shipTo(order) {
      if (!order.extension_attributes.shipping_assignments || !order.extension_attributes.shipping_assignments.length) {
        return ''
      }
      let address = order.extension_attributes.shipping_assignments[0].shipping.address
      let street = address.street[0]
      let appartment = address.street.length > 1 ? ' ' + address.street[1] : ''
      return `${street}${appartment}, ${address.city}, ${address.region}, ${address.postcode}`
    }
  }
}

</script>
<style lang="scss" scoped>

.loadingOrders {
  background-color: #bbbbbb;
  opacity: 0.5;
}
.account-orders-table {
  thead {
    tr {
      border-top: 0;
    }
  }

  th {
    @apply text-grey-11 font-normal text-left;
  }

  td {
    @apply font-bold;
  }

  th,
  td {
    @apply px-2 py-4 leading-none;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

</style>

<template>
  <div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Events & Classes - Past Events') }}
    </h2>
    <div v-if="past && past.length > 0">
      <account-class :eventItem="item" :key="index" v-for="(item, index) in past" />
    </div>
    <div v-else class="px-8.5 pt-xl pb-xl bg-white mb-5">
      You have no tickets for upcoming events or classes. Please visit the Education calendar to browse upcoming events and classes.
    </div>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import AccountClass from './AccountClass'
export default {
  name: 'PastEvents',
  components: {
    AccountClass
  },
  data () {
    return {
      past: []
    }
  },
  async beforeMount () {
    this.$bus.$emit('notification-progress-start', i18n.t('Loading Past Events ... '))
    this.$store.dispatch('events/getEvents', {
      'searchCriteria[filterGroups][0][filters][0][field]': 'customer_id',
      'searchCriteria[filterGroups][0][filters][0][value]': this.currentUser.id,
      'searchCriteria[filterGroups][0][filters][0][conditionType]': 'eq',
      'searchCriteria[filterGroups][1][filters][0][field]': 'event_end_date',
      'searchCriteria[filterGroups][1][filters][0][value]': this.getCurrentDate,
      'searchCriteria[filterGroups][1][filters][0][conditionType]': 'lt'
    }).then((response) => {
      this.$bus.$emit('notification-progress-stop')
      if (response) {
        this.past = response
      } else {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Error while loading \'Past Events\''),
          action1: { label: i18n.t('OK'), action: 'close' }
        })
      }
    }).catch((err) => {
      this.$bus.$emit('notification-progress-stop', err)
    })
  },
  computed: {
    currentUser() {
      return this.$store.state.user.current
    },
    getCurrentDate() {
      let currentDate = new Date()
      return `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    }
  }
}
</script>

<template>
  <div>
    <header class="-mb-30px relative z-10">
      <h1 class="text-primary text-heading-page heading--staggered leading-none">
        {{ id ? $t('Edit') : $t('Add New') }}
        <br>
        {{ $t('Address') }}
      </h1>
    </header>

    <div class="p30px md:p-60px bg-white">
      <hr class="my-30px border-t border-grey-4">
      <h2 class="uppercase text-sm leading-sm tracking-2px">
        {{ $t('Contact Information') }}
      </h2>
      <hr class="my-30px border-t border-grey-4">

      <div>
        <div class="flex flex-wrap">
          <base-input
            class="w-full md:w-1/2 md:pr-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="first-name"
            autocomplete="given-name"
            :placeholder="`${$t('First name')} *`"
            v-model.trim="addressDetails.firstName"
            @input="$v.addressDetails.firstName.$touch()"
            :validations="[
              {
                condition: !$v.addressDetails.firstName.required && $v.addressDetails.firstName.$error,
                text: $t('Field is required')
              },
              {
                condition: !$v.addressDetails.firstName.minLength,
                text: $t('Name must have at least 2 letters.')
              }
            ]"
          >
            {{ $t('First name') }}<span class="text-red-2">*</span>
          </base-input>

          <base-input
            class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="last-name"
            autocomplete="family-name"
            :placeholder="`${$t('Last name')} *`"
            v-model.trim="addressDetails.lastName"
            @input="$v.addressDetails.lastName.$touch()"
            :validations="[{
              condition: !$v.addressDetails.lastName.required && $v.addressDetails.lastName.$error,
              text: $t('Field is required')
            }]"
          >
            {{ $t('Last name') }}<span class="text-red-2">*</span>
          </base-input>
        </div>

        <base-input
          class="mt-4"
          input-class="bg-grey-5 mt-2"
          type="text"
          name="company"
          autocomplete="company"
          :placeholder="$t('Company')"
          v-model.trim="addressDetails.company"
        >
          {{ $t('Company') }}
        </base-input>

        <div class="flex flex-wrap mt-4">
          <base-input
            class="w-full md:w-1/2 md:pr-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="phone-number"
            autocomplete="tel"
            :placeholder="`${$t('Telephone')} *`"
            v-model.trim="addressDetails.phone"
            @input="$v.addressDetails.phone.$touch()"
            :validations="[
              {
                condition: !$v.addressDetails.phone.required && $v.addressDetails.phone.$error,
                text: $t('Field is required')
              }
            ]"
          >
            {{ $t('Telephone') }}<span class="text-red-2">*</span>
          </base-input>

          <base-input
            class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="fax-number"
            autocomplete="fax"
            :placeholder="$t('Fax')"
            v-model.trim="addressDetails.fax"
          >
            {{ $t('Fax') }}
          </base-input>
        </div>
      </div>

      <hr class="my-30px border-t border-grey-4">
      <h2 class="uppercase text-sm leading-sm tracking-2px">
        {{ $t('Address') }}
      </h2>
      <hr class="my-30px border-t border-grey-4">

      <div>
        <base-input
          input-class="bg-grey-5 mt-2"
          type="text"
          name="street-address"
          autocomplete="address-line1"
          :placeholder="`${$t('Street name')} *`"
          v-model.trim="addressDetails.street"
          @input="$v.addressDetails.street.$touch()"
          :validations="[{
            condition: !$v.addressDetails.street.required && $v.addressDetails.street.$error,
            text: $t('Field is required')
          }]"
        >
          {{ $t('Street Address') }}<span class="text-red-2">*</span>
        </base-input>

        <base-input
          class="mt-4"
          input-class="bg-grey-5"
          type="text"
          name="apartment-number"
          autocomplete="address-line2"
          :placeholder="`${$t('House/Apartment number')} *`"
          v-model.trim="addressDetails.house"
          @input="$v.addressDetails.house.$touch()"
          :validations="[{
            condition: !$v.addressDetails.house.required && $v.addressDetails.house.$error,
            text: $t('Field is required')
          }]"
        />

        <div class="flex flex-wrap mt-4">
          <base-input
            class="w-full md:w-1/2 md:pr-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="city"
            autocomplete="address-level2"
            :placeholder="`${$t('City')} *`"
            v-model.trim="addressDetails.city"
            @input="$v.addressDetails.city.$touch()"
            :validations="[{
              condition: !$v.addressDetails.city.required && $v.addressDetails.city.$error,
              text: $t('Field is required')
            }]"
          >
            {{ $t('City') }}<span class="text-red-2">*</span>
          </base-input>

          <base-input
            class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="state"
            autocomplete="address-level1"
            :placeholder="`${$t('State / Province')} *`"
            v-model.trim="addressDetails.region"
            @input="$v.addressDetails.region.$touch()"
            :validations="[{
              condition: !$v.addressDetails.region.required && $v.addressDetails.region.$error,
              text: $t('Field is required')
            }]"
          >
            {{ $t('State / Province') }}<span class="text-red-2">*</span>
          </base-input>
        </div>

        <div class="flex flex-wrap mt-4">
          <base-input
            class="w-full md:w-1/2 md:pr-4"
            input-class="bg-grey-5 mt-2"
            type="text"
            name="zip-code"
            autocomplete="postal-code"
            :placeholder="`${$t('Zip / Postal code')} *`"
            v-model.trim="addressDetails.postcode"
            @input="$v.addressDetails.postcode.$touch()"
            :validations="[
              {
                condition: !$v.addressDetails.postcode.required && $v.addressDetails.postcode.$error,
                text: $t('Field is required')
              },
              {
                condition: !$v.addressDetails.postcode.minLength,
                text: $t('Zip-code must have at least 3 letters.')
              }
            ]"
          >
            {{ $t('Zip / Postal code') }}<span class="text-red-2">*</span>
          </base-input>

          <base-select
            class="w-full md:w-1/2 mt-4 md:mt-0 md:pl-4"
            input-class="bg-grey-5 mt-2"
            name="countries"
            :options="countryOptions"
            :selected="addressDetails.country"
            :placeholder="$t('Country *')"
            :validations="[
              {
                condition: $v.addressDetails.country.$error && !$v.addressDetails.country.required,
                text: $t('Field is required')
              }
            ]"
            v-model="addressDetails.country"
            autocomplete="country-name"
            @blur="$v.addressDetails.country.$touch()"
            @change="$v.addressDetails.country.$touch()"
          >
            {{ $t('Country') }}<span class="text-red-2">*</span>
          </base-select>
        </div>

        <base-checkbox
          class="mt-4"
          id="is-shipping-address"
          v-model="useAsShipping"
          @click="useAsShipping = !useAsShipping"
          :disabled="isShipping"
        >
          {{ isShipping ? $t('Default shipping address') : $t('Use as my default shipping address') }}
        </base-checkbox>

        <base-checkbox
          class="mt-4"
          id="is-billing-address"
          v-model="useAsBilling"
          @click="useAsBilling = !useAsBilling"
          :disabled="isBilling"
        >
          {{ isBilling ? $t('Default billing address') : $t('Use as my default billing address') }}
        </base-checkbox>
      </div>

      <hr class="mt-16 mb-2 border-t border-grey-10">

      <p class="text-right text-red-2 mb-3">
        * {{ $t('Required Fields') }}
      </p>

      <div class="flex">
        <back-button />

        <button-outline
          class="ml-auto"
          @click.native="updateDetails"
          :disabled="$v.$invalid"
        >
          {{ $t('Save Address') }}
        </button-outline>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import toString from 'lodash-es/toString'

import ButtonOutline from 'theme/components/theme/ButtonOutline'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BackButton from './BackButton'

const Countries = require('@vue-storefront/i18n/resource/countries.json')

export default {
  name: 'MyAddressEdit',
  components: {
    ButtonOutline,
    BaseInput,
    BaseSelect,
    BaseCheckbox,
    BackButton
  },
  data () {
    return {
      addressDetails: {
        firstName: '',
        lastName: '',
        company: '',
        street: '',
        house: '',
        city: '',
        postcode: '',
        region: '',
        country: '',
        phone: '',
        fax: ''
      },
      countries: Countries,
      currentUser: null,
      id: null,
      isShipping: false,
      isBilling: false,
      useAsShipping: false,
      useAsBilling: false
    }
  },
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  beforeMount () {
    this.$bus.$on('user-after-loggedin', this.onLoggedIn)
  },
  beforeDestroy () {
    this.$bus.$off('user-after-loggedin', this.onLoggedIn)
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.currentUser = Object.assign({}, this.$store.state.user.current)

      if (this.$route.params.id) {
        this.id = this.$route.params.id
      } else {
        this.id = null
      }

      this.addressDetails = this.getAddressDetails()

      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('default_shipping')) {
          if (toString(this.currentUser.default_shipping) === toString(this.id)) {
            this.isShipping = true
            this.useAsShipping = true
          } else {
            this.isShipping = false
            this.useAsShipping = false
          }
        } else {
          this.isShipping = true
          this.useAsShipping = true
        }

        if (this.currentUser.hasOwnProperty('default_billing')) {
          if (toString(this.currentUser.default_billing) === toString(this.id)) {
            this.isBilling = true
            this.useAsBilling = true
          } else {
            this.isBilling = false
            this.useAsBilling = false
          }
        } else {
          this.isBilling = true
          this.useAsBilling = true
        }
      } else {
        this.isShipping = false
        this.useAsShipping = false
        this.isBilling = false
        this.useAsBilling = false
      }
    },
    onLoggedIn () {
      this.load()
    },
    getAddressDetails () {
      if (this.currentUser) {
        let index = -1

        for (let i = 0; i < this.currentUser.addresses.length; i++) {
          if (toString(this.currentUser.addresses[i].id) === toString(this.id)) {
            index = i
            break
          }
        }
        if (index !== -1) {
          return {
            firstName: this.currentUser.addresses[index].firstname,
            lastName: this.currentUser.addresses[index].lastname,
            company: this.currentUser.addresses[index].hasOwnProperty('company') ? this.currentUser.addresses[index].company : '',
            street: this.currentUser.addresses[index].street[0],
            house: this.currentUser.addresses[index].street[1],
            city: this.currentUser.addresses[index].city,
            postcode: this.currentUser.addresses[index].postcode,
            region: this.currentUser.addresses[index].region.region ? this.currentUser.addresses[index].region.region : '',
            country: this.currentUser.addresses[index].country_id,
            phone: this.currentUser.addresses[index].telephone,
            fax: this.currentUser.addresses[index].hasOwnProperty('fax') ? this.currentUser.addresses[index].fax : ''
          }
        } else {
          return {
            firstName: this.currentUser.firstname,
            lastName: this.currentUser.lastname,
            company: '',
            street: '',
            house: '',
            city: '',
            postcode: '',
            region: '',
            country: '',
            phone: '',
            fax: ''
          }
        }
      } else {
        return {
          firstName: '',
          lastName: '',
          company: '',
          street: '',
          house: '',
          city: '',
          postcode: '',
          region: '',
          country: '',
          phone: '',
          fax: ''
        }
      }
    },
    objectsEqual (a, b) {
      const aProps = Object.keys(a)
      const bProps = Object.keys(b)

      if (aProps.length !== bProps.length) {
        return false
      }

      for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i]

        if (!b.hasOwnProperty(propName)) {
          return false
        } else {
          if (a[propName] !== null && b[propName] !== null && a[propName] === 'object' && b[propName] === 'object') {
            if (!this.objectsEqual(a[propName], b[propName])) {
              return false
            }
          } else if (a[propName] !== b[propName]) {
            return false
          }
        }
      }
      return true
    },
    updateDetails () {
      if (!this.objectsEqual(this.addressDetails, this.getAddressDetails()) ||
        (!this.isShipping && this.useAsShipping) || (!this.isBilling && this.useAsBilling)) {
        let updatedDetails = JSON.parse(JSON.stringify(this.$store.state.user.current))

        if (this.id) {
          let index = -1
          let shippingIndex = -1
          let billingIndex = -1

          for (let i = 0; i < this.currentUser.addresses.length; i++) {
            if (toString(this.currentUser.addresses[i].id) === toString(this.id)) {
              index = i
            }

            if (toString(this.currentUser.addresses[i].id) === toString(this.currentUser.default_shipping)) {
              shippingIndex = i
            }

            if (toString(this.currentUser.addresses[i].id) === toString(this.currentUser.default_billing)) {
              billingIndex = i
            }
          }

          if (index !== -1) {
            updatedDetails.addresses[index].firstname = this.addressDetails.firstName
            updatedDetails.addresses[index].lastname = this.addressDetails.lastName
            updatedDetails.addresses[index].company = this.addressDetails.company ? this.addressDetails.company : ''
            updatedDetails.addresses[index].street = [this.addressDetails.street, this.addressDetails.house]
            updatedDetails.addresses[index].city = this.addressDetails.city
            updatedDetails.addresses[index].region = {
              region: this.addressDetails.region ? this.addressDetails.region : null
            }
            updatedDetails.addresses[index].country_id = this.addressDetails.country
            updatedDetails.addresses[index].postcode = this.addressDetails.postcode
            updatedDetails.addresses[index].telephone = this.addressDetails.phone
            updatedDetails.addresses[index].fax = this.addressDetails.fax ? this.addressDetails.fax : ''

            if (!this.isShipping && this.useAsShipping) {
              updatedDetails.addresses[index].default_shipping = true

              if (shippingIndex !== -1 && updatedDetails.addresses[shippingIndex].hasOwnProperty('default_shipping')) {
                delete updatedDetails.addresses[shippingIndex].default_shipping
              }
            }

            if (!this.isBilling && this.useAsBilling) {
              updatedDetails.addresses[index].default_billing = true

              if (billingIndex !== -1 && updatedDetails.addresses[billingIndex].hasOwnProperty('default_billing')) {
                delete updatedDetails.addresses[billingIndex].default_billing
              }
            }
          } else {
            updatedDetails = null
          }
        } else {
          updatedDetails.addresses.push({
            firstname: this.addressDetails.firstName,
            lastname: this.addressDetails.lastName,
            ...(this.addressDetails.company ? { company: this.addressDetails.company } : {}),
            street: [this.addressDetails.street, this.addressDetails.house],
            city: this.addressDetails.city,
            ...(this.addressDetails.region ? { region: { region: this.addressDetails.region } } : {}),
            country_id: this.addressDetails.country,
            postcode: this.addressDetails.postcode,
            telephone: this.addressDetails.phone,
            ...(this.addressDetails.fax ? { fax: this.addressDetails.fax } : {}),
            ...(this.useAsShipping ? { default_shipping: true } : {}),
            ...(this.useAsBilling ? { default_billing: true } : {})
          })
        }

        if (updatedDetails) {
          this.$bus.$emit('myAccount-before-updateUser', updatedDetails)
        }
      }
    }
  },
  validations: {
    addressDetails: {
      firstName: {
        required,
        minLength: minLength(2)
      },
      lastName: {
        required
      },
      phone: {
        required
      },
      country: {
        required
      },
      street: {
        required
      },
      house: {
        required
      },
      region: {
        required
      },
      postcode: {
        required,
        minLength: minLength(3)
      },
      city: {
        required
      }
    }
  }
}
</script>

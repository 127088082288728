<template>
  <div class="mb-5 px-sm lg:px-xl pt-sm lg:pt-8.5 pb-sm lg:pb-8.5 bg-white">
    <div class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Address Book') }}
    </div>
    <div class="lg:pt-5 lg:px-8.5 lg:pr-13">
      <div class="lg:flex">
        <div class="w-full lg:w-1/2">
          <div class="my-5 italic text-mid font-serif leading-h4 tracking-tiny">
            {{ $t('Default Billing Address') }}
          </div>
          <my-address :address="billingAddress" class="text-dark text-heading-page tracking-xs leading-h5 p-md lg:p-xl border border-solid border-grey-35 lg:mr-xs mb-6.5" is-billing />
        </div>
        <div class="w-full lg:w-1/2">
          <div class="my-5 italic text-mid font-serif leading-h4 tracking-tiny">
            {{ $t('Default Shipping Address') }}
          </div>
          <my-address :address="shippingAddress" class="text-dark text-heading-page tracking-xs leading-h5 p-md lg:p-xl border border-solid border-grey-35 lg:ml-xs mb-6.5" is-shipping />
        </div>
      </div>
      <div class="text-average leading-h5 tracking-xs text-dark mb-md">
        <span>
          {{ $t('To edit, add or remove shipping addresses please contact your account consultant: ') }}
        </span>
        <a href="tel:305-600-1305" class="text-brown-2 hover:underline">305-600-1305</a>
      </div>
      <div class="lg:flex">
        <div class="w-full lg:w-1/2">
           <div class="my-5 italic text-mid font-serif leading-h4 tracking-tiny">
            {{ $t('Additional Addresses') }}
          </div>
          <template v-if="additionalAddresses.length">
            <my-address v-for="(address, index) in additionalAddresses" :key="index" :address="address" class="leading-h5 p-md lg:p-xl border border-solid border-grey-35 lg:mr-xs mb-6.5" />
          </template>
          <p v-else class="leading-h5 p-xl border border-solid border-grey-35 mr-xs mb-6.5">
            {{ $t('You have no additional address entries in your address book.') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressBook from 'theme/mixins/blocks/AddressBook'
import MyAddress from './MyAddress'
export default {
  name: 'MyAddressBook',
  components: {
    MyAddress
  },
  mixins: [AddressBook]
}

</script>

<template>
  <div>
    <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Web Profit Share') }}
    </h2>
    <div class="bonus-block bg-white lg:flex mb-5">
      <div class="bonus-block__left px-5 lg:px-8.5 pt-xl pb-xl lg:w-2/3 w-full">
        <h3 class="bonus-block__title mb-6.5 mt-6.5 uppercase text-lg font-sans leading-h2 tracking-lg font-black">
          Earn Bonus Cash When You Refer Your Clients.
        </h3>
        <p class="bonus-block__description font-sans text-above-md leading-sm mb-4">
          It’s not always possible for someone to get to your salon to pick up their favorite LBP products.
          Now, your can earn money when your clients shop online at our brand sites. Simply use the embed codes
          below to add referral links or graphics to your website. When clients click on these links, they will
          be taken to the brand site that associates them with your salon. Once they are in our system as your
          client, they will automatically be linked to your salon on future visits, even if they go directly to
          the brand site. You will earn a percentage of any web sales associated with your salon.
        </p>
        <router-link
          :to="localizedRoute('/static/rewards-program/web-profits')"
          class="bonus-block__link uppercase text-sm relative tracking-lg text-dark-4 leading-none relative inline-block"
        >
          {{ $t('Learn how it works') }}
        </router-link>
      </div>
      <div class="bonus-block__right bg-brown-4 px-4 lg:px-8.5 pt-xl pb-xl flex flex-col justify-between items-start lg:w-2/3 w-full">
        <ul>
          <li class="lg:mt-7">
            <span class="text-white block text-sm uppercase tracking-mid leading-h6 font-bold">{{ $t('Profit Earned This Year') }}</span>
            <span class="text-white block text-sm uppercase tracking-mid leading-h6 font-bold">(As Of Apr 12)</span>
            <span class="text-white block text-lg uppercase tracking-mid leading-none font-black">$0.00</span>
          </li>
          <li class="mt-3 lg:mt-7">
            <span class="text-white block text-sm uppercase tracking-mid leading-h6 font-bold">{{ $t('Current Profit Share Rate') }}</span>
            <span class="text-white block text-lg uppercase tracking-mid leading-none font-black">30%</span>
          </li>
          <li class="mt-3 lg:mt-7">
            <span class="text-white block text-sm uppercase tracking-mid leading-h6 font-bold">
              {{ $t('In order to receive your Web Profit Share each quarter please complete the W-9 Form and provide. it to your account manager.') }}
            </span>
            <a
              href="/assets/fw9.pdf"
              class="bonus-block__button hover:bg-brown-2 hover:text-white mt-sm inline-block bg-black text-white text-sm uppercase w-full pr-10 text-center tracking-sm"
            >
              {{ $t('Download W9 Form') }}
            </a>
          </li>
        </ul>
        <router-link
          class="bonus-block__link bonus-block__link--light uppercase text-sm relative tracking-lg text-white leading-none inline-block relative mt-4 mg:mt-0"
          :to="localizedRoute('/static/customer/webprofit/detail')"
        >
          {{ $t('View detailed earnings') }}
        </router-link>
      </div>
    </div>
    <div class="referral-block mb-5 bg-white px-4 lg:px-8.5 pt-xl pb-xl">
      <div class="italic text-mid leading-h4 font-serif text-black tracking-tiny mb-10">
        <span>{{ $t('Customize Your Referral Page') }}</span>
      </div>
      <div class="flex justify-between">
        <div class="referral-block__left pl-2 w-30%">
          <img src="https://pro.luxurybrandpartners.com/media/customer/a/p/apple-touch-icon.png">
          <div class="leading-h4">
            <a href="#" class="referral-block__link uppercase text-sm relative tracking-lg text-dark-4 leading-none relative inline-block">
              {{ $t('Upload a New Logo') }}
            </a>
          </div>
        </div>
        <div class="referral-block__right w-3/5">
          <p class="referral-block__description font-sans text-above-md leading-sm tracking-xs">
            When you refer a client to one of our brand sites, the first thing they will see when they arrive is a
            welcome message that includes your salon logo. Make sure you have an up-to-date logo on file to enhance
            your clients experience when they make the hand-off from your site to ours.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WebprofitShare'
}
</script>

<style lang="scss" scoped>
  $brown-4: #bfad78;
  $brown-5: #916f37;
  .bonus-block {
    &__right {
      background-image: linear-gradient(to bottom right, $brown-4 0%, $brown-5 100%);
    }
    &__title {
      @screen lg {
        max-width: 350px;
      }
    }
    &__description {
      @screen lg {
        max-width: 445px;
      }
    }
    &__link {
      &:after {
        @screen lg {
          @apply absolute bg-grey-37 w-full;
          bottom: 0;
          content: '';
          height: 1px;
          left: 0;
        }
      }
      &--light {
        &:after {
          @apply absolute bg-white w-full;
        }
      }
    }
    &__button {
      height: 36px;
      line-height: 36px;
      padding-left: 22px;
      transition: background-color .5s ease, border-color .5s ease, color .5s ease;
    }
  }
  .referral-block {
    &__description {
      @screen lg {
        max-width: 310px;
      }
    }
    &__link {
      &:after {
        @screen lg {
          @apply absolute bg-grey-37 w-full;
          bottom: 0;
          content: '';
          height: 1px;
          left: 0;
        }
      }
    }
  }
</style>

<template>
  <div class="rewards">
    <h2 class="text-primary md:font-normal font-sans text-h2 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Reward Details') }}
    </h2>
    <earning-detail :dashboard="dashboardInfo"/>
<!--    <earn-more />-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EarningDetail from 'theme/components/theme/blocks/Account/EarningDetail'

export default {
  name: 'Rewards',
  components: {
    EarningDetail
  },
  computed: {
    ...mapGetters({
      dashboardInfo: 'loyalty/getDashboard'
    }),
    nextTierExists () {
      return this.dashboardInfo ? !!this.dashboardInfo.next_tier : false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-xl pt-8.5 pb-11 bg-white"},[_c('h2',{staticClass:"italic text-mid leading-h4 font-serif text-black tracking-tiny mb-5 font-normal"},[_vm._v("\n      "+_vm._s(_vm.$t('Account Information'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"w-2/3 mb-5"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('To edit your first name, last name or email address please contact your account consultant:'))+"\n      ")]),_vm._v(" "),_c('a',{staticClass:"text-brown-2 hover:underline",attrs:{"href":"tel:305-600-1305"}},[_vm._v("305-600-1305")])]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap"},[_c('base-input',{staticClass:"w-full md:w-1/2 md:pr-2",attrs:{"input-class":"mt-1","type":"text","name":"first-name","autocomplete":"given-name","placeholder":_vm.$t('First name'),"validations":[
          {
            condition: !_vm.$v.currentUser.firstname.required,
            text: _vm.$t('Field is required')
          },
          {
            condition: !_vm.$v.currentUser.firstname.minLength,
            text: _vm.$t('Name must have at least 2 letters.')
          }
        ]},on:{"input":function($event){return _vm.$v.currentUser.firstname.$touch()}},model:{value:(_vm.currentUser.firstname),callback:function ($$v) {_vm.$set(_vm.currentUser, "firstname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentUser.firstname"}},[_c('span',{staticClass:"text-md"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('First Name'))+"\n      ")]),_vm._v(" "),_c('base-input',{staticClass:"w-full md:w-1/2 mt-4 md:mt-0 md:pl-2",attrs:{"input-class":"mt-1","type":"text","name":"last-name","autocomplete":"family-name","placeholder":_vm.$t('Last name'),"validations":[{
          condition: !_vm.$v.currentUser.lastname.required,
          text: _vm.$t('Field is required')
        }]},on:{"input":function($event){return _vm.$v.currentUser.lastname.$touch()}},model:{value:(_vm.currentUser.lastname),callback:function ($$v) {_vm.$set(_vm.currentUser, "lastname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentUser.lastname"}},[_c('span',{staticClass:"text-md"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Last Name'))+"\n      ")])],1),_vm._v(" "),_c('base-input',{staticClass:"mt-4",attrs:{"input-class":"mt-1","type":"email","name":"email-address","autocomplete":"email","placeholder":_vm.$t('Email address'),"validations":[
        {
          condition: !_vm.$v.currentUser.email.required,
          text: _vm.$t('Field is required')
        },
        {
          condition: !_vm.$v.currentUser.email.email,
          text: _vm.$t('Please provide valid e-mail address.')
        }
      ]},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}},[_c('span',{staticClass:"text-md"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Email Address'))+"\n    ")]),_vm._v(" "),_c('base-checkbox',{staticClass:"mt-4",attrs:{"id":"changePassword"},on:{"click":function($event){_vm.changePassword = !_vm.changePassword}},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}},[_vm._v("\n      "+_vm._s(_vm.$t('Change password'))+"\n    ")]),_vm._v(" "),(_vm.changePassword)?[_c('base-input',{staticClass:"w-full md:w-1/2 mt-4 md:pr-2",attrs:{"input-class":"mt-1","type":"password","name":"old-password","autocomplete":"current-password","placeholder":_vm.$t('Current password *'),"validations":[{
          condition: !_vm.$v.oldPassword.required && _vm.$v.oldPassword.$error,
          text: _vm.$t('Field is required')
        }]},on:{"input":function($event){return _vm.$v.oldPassword.$touch()}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}},[_c('span',{staticClass:"text-md"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Current password'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap mt-4"},[_c('base-input',{staticClass:"w-full md:w-1/2 md:pr-2",attrs:{"input-class":"mt-1","type":"password","name":"password","autocomplete":"new-password","placeholder":_vm.$t('New password *'),"validations":[{
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required')
          }]},on:{"input":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('span',{staticClass:"text-md"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('New password'))+"\n        ")]),_vm._v(" "),_c('base-input',{staticClass:"w-full md:w-1/2 mt-4 md:mt-0 md:pl-2",attrs:{"input-class":"mt-1","type":"password","name":"password-confirm","autocomplete":"new-password","placeholder":_vm.$t('Confirm new password *'),"validations":[
            {
              condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
              text: _vm.$t('Field is required')
            },
            {
              condition: !_vm.$v.rPassword.sameAsPassword,
              text: _vm.$t('Passwords must be identical.')
            }
          ]},on:{"input":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}},[_c('span',{staticClass:"text-md"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Confirm New password'))+"\n        ")])],1)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex mt-10 mb-3"},[_c('back-button',{staticClass:"mr-sm"}),_vm._v(" "),_c('button-full',{staticClass:"save-btn relative w-1/4",attrs:{"disabled":_vm.checkValidation()},nativeOn:{"click":function($event){return _vm.updateProfile($event)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Save')))]),_vm._v(" "),_c('i',{staticClass:"ss-gizmo save-icon ss-right absolute text-h5 right-20px top-50%"})])],1),_vm._v(" "),_c('p',{staticClass:"uppercase tracking-md text-h6 leading-none"},[_vm._v("\n      * "+_vm._s(_vm.$t('Required Fields'))+"\n    ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
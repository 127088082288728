<template>
  <div>
    <!-- My orders header -->
    <h1 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Orders') }}
    </h1>
    <!-- My orders body -->
    <div class="bg-white lg:py-xl lg:px-8.5 p-md">
      <orders-table />
    </div>
  </div>
</template>

<script>
import MyOrders from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyOrders'
import OrdersTable from './OrdersTable'

export default {
  components: {
    OrdersTable
  },
  mixins: [MyOrders],
  methods: {
    shipTo (order) {
      if (!order.extension_attributes.shipping_assignments || !order.extension_attributes.shipping_assignments.length) {
        return ''
      }

      let address = order.extension_attributes.shipping_assignments[0].shipping.address
      let street = address.street[0]
      let appartment = address.street.length > 1 ? ' ' + address.street[1] : ''

      return `${street}${appartment}, ${address.city}, ${address.region}, ${address.postcode}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .account-orders-table {

    thead {
      tr {
        border-top: 0;
      }
    }

    th {
      @apply text-grey-11 font-normal text-left;
    }

    td {
      @apply font-bold;
    }

    th,td {
      @apply px-2 py-4 leading-none;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
</style>
